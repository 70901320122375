import { distance3d } from "./modes";
import { getAngleBetween3d } from "./coordinates";
import { PTRAP_FIXTURES, TOILET_TYPE } from "../consts/editor";

const ALLOWED_WIDTHS = [2, 3, 4];
const ALLOWED_ANGLES_WYE = [45, 90];
const ALLOWED_ANGLES_ELBOWS = [180, 90, 135, 157.5];

export const JOINT_TYPES: Record<string, string> = {
  WYE: "WYE",
  ELBOW: "ELBOW",
  BEND: "BEND",
  TEE: "TEE",
  FITTING: "FITTING",
  FLUSH_BUSHING: "FLUSH_BUSHING",
  ELBOW_45: "ELBOW_45",
  ELBOW_22_5: "ELBOW_22_5",
  ELBOW_90: "ELBOW_90",
  ELBOW_STRAIGHT: "ELBOW_STRAIGHT",
  CLOSET_BEND: "CLOSET_BEND",
  TEE_INLET: "TEE_INLET",
  DOUBLE_SAN_TEE: "DOUBLE_SAN_TEE",
  P_TAP: "P_TAP",
};

export interface JointDetails {
  type: string;
  typeDetails: string;
  width: number;
  mainJoints: any[];
  isSmooth?: boolean;
  secondaryJoints: any[];
}

const isAngleAllowedWithTolerance = (
  angle: number,
  angles = ALLOWED_ANGLES_ELBOWS,
  tolerance: number = 1
) => {
  return angles.some((a) => {
    return angle - tolerance <= a && angle + tolerance >= a;
  });
};

function getWyeJointDetails(sharedPointDetails: any): JointDetails | null {
  const { neighbours, controlPoint } = sharedPointDetails;

  const maxPipelineWidth = neighbours.reduce(
    (acc: number, n: any) => Math.max(acc, n[1] || 0),
    0
  );

  const mainJoints: any[] = [];
  const secondaryJoints: any[] = [];

  let maxAngle = 0;
  let bestPair: number[] = [];

  for (let i = 0; i < neighbours.length; i++) {
    const neighbour = neighbours[i];

    if (neighbour[1] >= maxPipelineWidth) {
      neighbours.forEach((n: any, idx: number) => {
        if (idx !== i) {
          const angle = getAngleBetween3d(neighbour[0], controlPoint, n[0]);
          if (angle > maxAngle) {
            maxAngle = angle;
            bestPair = [i, idx];
          }
        }
      });
    }
  }

  for (let i = 0; i < neighbours.length; i++) {
    if (bestPair.includes(i)) {
      mainJoints.push(neighbours[i]);
    } else {
      secondaryJoints.push(neighbours[i]);
    }
  }

  const mainAngle = getAngleBetween3d(
    mainJoints[0][0],
    controlPoint,
    mainJoints[1][0]
  );

  const secondaryAngle = Math.min(
    getAngleBetween3d(mainJoints[0][0], controlPoint, secondaryJoints[0][0]),
    getAngleBetween3d(mainJoints[1][0], controlPoint, secondaryJoints[0][0])
  );

  if (isAngleAllowedWithTolerance(mainAngle, [180])) {
    if (isAngleAllowedWithTolerance(secondaryAngle, ALLOWED_ANGLES_WYE)) {
      const mainWidth = mainJoints[0][1];
      const secondaryWidth = secondaryJoints[0][1];

      const isComboWye = isAngleAllowedWithTolerance(secondaryAngle, [90]);
      const isReducingWye = mainWidth !== secondaryWidth;

      const typeDetails = `${isReducingWye ? "REDUCING_" : ""}${
        isComboWye ? "COMBO_" : ""
      }WYE`;

      if (
        mainJoints[0][1] === mainJoints[1][1] &&
        ALLOWED_WIDTHS.includes(mainWidth) &&
        ALLOWED_WIDTHS.includes(secondaryWidth)
      ) {
        return {
          type: JOINT_TYPES.WYE,
          mainJoints,
          typeDetails,
          secondaryJoints,
          width: mainWidth,
          isSmooth: isComboWye,
        };
      }
    }
  }

  return null;
}

function getElbowJointDetails(sharedPointDetails: any): JointDetails | null {
  const { neighbours, controlPoint } = sharedPointDetails;

  // Default to Elbow:
  const connectionDegrees = getAngleBetween3d(
    neighbours[0][0],
    controlPoint,
    neighbours[1][0]
  );
  const angle = Math.round(connectionDegrees);
  // allow for +1 and -1 degree tolerance
  const tolerance = 2;
  const fitInAllowedAngles = ALLOWED_ANGLES_ELBOWS.filter(
    (a) => angle - tolerance <= a && angle + tolerance >= a
  );

  // angle validation first
  if (fitInAllowedAngles.length > 0) {
    let elbowType;
    if (fitInAllowedAngles.includes(135)) {
      elbowType = JOINT_TYPES.ELBOW_45;
    } else if (fitInAllowedAngles.includes(157.5)) {
      elbowType = JOINT_TYPES.ELBOW_22_5;
    } else if (fitInAllowedAngles.includes(90)) {
      elbowType = JOINT_TYPES.ELBOW_90;
    } else if (fitInAllowedAngles.includes(180)) {
      elbowType = JOINT_TYPES.ELBOW_STRAIGHT;
    } else {
      return null;
    }

    // Width validation : only allow 2, 3, and 4 inches connections, and same width
    if (
      neighbours[0][1] === neighbours[1][1] &&
      ALLOWED_WIDTHS.includes(neighbours[0][1])
    ) {
      return {
        type: JOINT_TYPES.ELBOW,
        typeDetails: elbowType,
        width: neighbours[0][1],
        mainJoints: neighbours,
        secondaryJoints: [],
      };
    }

    if (
      neighbours[0][1] !== neighbours[1][1] &&
      elbowType === JOINT_TYPES.ELBOW_STRAIGHT
    ) {
      return {
        type: JOINT_TYPES.FLUSH_BUSHING,
        typeDetails: JOINT_TYPES.FLUSH_BUSHING,
        width: neighbours[0][1],
        mainJoints: neighbours,
        secondaryJoints: [],
      };
    }

    return null;
  }
}

export function getClosetBendDetails(
  sharedPointDetails: any
): JointDetails | null {
  const { neighbours } = sharedPointDetails;
  const isSomeVertical = neighbours.some((n: any) => n[2] === true);
  const isSomeHorizontal = neighbours.some((n: any) => n[2] === false);

  // vertical is 4 inches, and horizontal is 3 inches
  if (isSomeVertical && isSomeHorizontal) {
    return {
      type: JOINT_TYPES.BEND,
      typeDetails: JOINT_TYPES.CLOSET_BEND,
      width: neighbours[0][1],
      mainJoints: neighbours,
      secondaryJoints: [],
    };
  }

  return null;
}

export function getTeeDetails(sharedPointDetails: any): JointDetails | null {
  const { controlPoint, neighbours } = sharedPointDetails;

  const mainJoints = neighbours.filter((n: any) => n[2] === true);
  const secondaryJoints = neighbours.filter((n: any) => n[2] === false);

  if (mainJoints.length === 2 && secondaryJoints.length === 1) {
    // width validation
    const verticalPipesWidth = mainJoints[0][1];
    const secondaryPipeWidth = secondaryJoints[0][1];

    if (
      mainJoints[0][1] === mainJoints[1][1] &&
      ALLOWED_WIDTHS.includes(verticalPipesWidth) &&
      ALLOWED_WIDTHS.includes(secondaryPipeWidth)
    ) {
      return {
        type: JOINT_TYPES.TEE,
        typeDetails: JOINT_TYPES.TEE,
        width: verticalPipesWidth,
        mainJoints,
        secondaryJoints,
      };
    }
  }

  if (mainJoints.length === 2 && secondaryJoints.length === 2) {
    const secondaryAngle = getAngleBetween3d(
      secondaryJoints[0][0],
      controlPoint,
      secondaryJoints[1][0]
    );

    // check for secondary angle validation
    if (isAngleAllowedWithTolerance(secondaryAngle, [90])) {
      if (
        mainJoints[0][1] === mainJoints[1][1] &&
        secondaryJoints[0][1] !== secondaryJoints[1][1]
      ) {
        return {
          type: JOINT_TYPES.TEE,
          typeDetails: JOINT_TYPES.TEE_INLET,
          width: mainJoints[0][1],
          mainJoints,
          secondaryJoints,
        };
      }
    }

    if (isAngleAllowedWithTolerance(secondaryAngle, [180])) {
      if (
        mainJoints[0][1] === 2 &&
        mainJoints[1][1] === 2 &&
        secondaryJoints[0][1] === 2 &&
        secondaryJoints[1][1] === 2
      ) {
        return {
          type: JOINT_TYPES.TEE,
          typeDetails: JOINT_TYPES.DOUBLE_SAN_TEE,
          width: mainJoints[0][1],
          mainJoints,
          secondaryJoints,
        };
      }
    }

    return null;
  }

  return null;
}

function getVerticalFittingOrFlushBushingDetails(
  sharedPointDetails: any
): JointDetails | null {
  const isReducing =
    sharedPointDetails.neighbours[0][1] !== sharedPointDetails.neighbours[1][1];

  if (isReducing) {
    return {
      type: JOINT_TYPES.FLUSH_BUSHING,
      typeDetails: JOINT_TYPES.FLUSH_BUSHING,
      width: sharedPointDetails.neighbours[0][1],
      mainJoints: sharedPointDetails.neighbours,
      secondaryJoints: [],
    };
  }
}

function getSortedFixtures(fixtures: any[], controlPoint: any[]) {
  return fixtures
    .map((f) => ({
      feature: f,
      distance: distance3d(
        controlPoint[0],
        controlPoint[1],
        controlPoint[2] || 0,
        f.geometry.coordinates[0],
        f.geometry.coordinates[1],
        f.geometry.coordinates[2] || 0
      ),
    }))
    .sort((a, b) => a.distance - b.distance);
}

export function getPTrapDetails(sharedPointDetails: any): JointDetails | null {
  const { neighbours } = sharedPointDetails;
  if (neighbours[0][1] === 2 && neighbours[1][1] === 2) {
    return {
      type: JOINT_TYPES.P_TAP,
      typeDetails: JOINT_TYPES.P_TAP,
      width: 2,
      mainJoints: sharedPointDetails.neighbours,
      secondaryJoints: [],
    };
  }

  return null;
}

export function getJointDetails(
  sharedPointDetails: any,
  fixtures: any[]
): JointDetails | null {
  const { neighbours, isVertical } = sharedPointDetails;

  if (!isVertical) {
    if (neighbours.length === 2) {
      return getElbowJointDetails(sharedPointDetails);
    }

    if (neighbours.length === 3) {
      return getWyeJointDetails(sharedPointDetails);
    }
  }

  if (isVertical) {
    if (neighbours.length === 2) {
      const isAllVertical = neighbours.every((n: any) => n[2] === true);
      if (isAllVertical) {
        return null;
        // add later once the logic for vertical bushings is implemented
        // return getVerticalFittingOrFlushBushingDetails(sharedPointDetails);
      }

      const toiletFixtures = getSortedFixtures(
        fixtures.filter((f) => f.properties.className === TOILET_TYPE),
        sharedPointDetails.controlPoint
      );

      const PTrapFixtures = getSortedFixtures(
        fixtures.filter((f) => PTRAP_FIXTURES.includes(f.properties.className)),
        sharedPointDetails.controlPoint
      );

      if (toiletFixtures?.length > 0 && toiletFixtures[0].distance < 30) {
        return getClosetBendDetails(sharedPointDetails);
      }

      if (PTrapFixtures?.length > 0 && PTrapFixtures[0].distance < 10) {
        return getPTrapDetails(sharedPointDetails);
      }
    } else {
      return getTeeDetails(sharedPointDetails);
    }
  }

  return null;
}
