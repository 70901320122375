import { FeatureCollection } from "geojson";
import { LinearInterpolator } from "@deck.gl/core";

import { last } from "../../utils/functional/list";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { GEOJSON_TYPES, MODES_IDS } from "../../consts/editor";
import {
  getStateTooltip,
  createSnapTargets,
  getDistanceTooltip,
  getFirstPointSnapped,
  createTentativeFeature,
} from "../../utils/coordinates";
import {
  getPickedEditHandle,
  getEditHandlesForFeature,
} from "../../utils/modes";
import {
  MODE_SPHERE,
  MODE_RECTANGLE,
  ARC_MODE_CIRCLE,
  ARC_MODE_QUADRATIC,
  DEFAULT_ARC_SEGMENTS,
} from "../../consts/coordinates";
import { CANVAS } from "../../consts/events";
import { getInitialCanvasState, getPanningVector } from "../../utils/autopan";
import { EditableLayerDraggingEvent } from "../../modes/base/editable-layer";

interface ARC_SECTION {
  id: string;
  type: 0 | 1;
  endPointIdx: number;
  startPointIdx: number;
  controlPoint: number[];
  controlPointIdx: number;
  ignoreIndices: number[];
}

const DRAW_MODES_IDS = {
  POLYGON: 1,
  ARC: 2,
  RECTANGLE: 3,
  CIRCLE: 4,
};

export class CommonDrawMode extends GeoJsonEditMode {
  firstPoint: any;
  mainFeature: any;
  indexedTargets: any;
  _ignoreCLick: boolean;
  readonly _lock: boolean;

  _panState: any;
  _panAnimationProps: any;

  _arcs: ARC_SECTION[] = [];
  _isArcMode: boolean = false;
  _isHeightMode: boolean = false;
  _isControlPoint: boolean = false;
  _tempArcStartIndex: number = 0;
  _tempArcMode: number = ARC_MODE_CIRCLE;
  _tempArcSegments: number = DEFAULT_ARC_SEGMENTS;

  constructor() {
    super();

    this._lock = false;
    this._ignoreCLick = false;
  }

  _resetState = () => {
    this.stopAnimationLoop();
    this.resetClickSequence();

    this._ignoreCLick = false;

    this._panState = null;
    this._panAnimationProps = null;

    this._arcs = [];
    this.firstPoint = null;
    this.mainFeature = null;
    this.indexedTargets = null;
    this._isControlPoint = false;
    this._tempArcStartIndex = 0;
    this._tempArcSegments = DEFAULT_ARC_SEGMENTS;

    this.dispatchEvent(this._tempArcMode + 1, 0);

    this._disableEdgePanning();
  };

  getGuides = (props: any) => {
    const guideType = props?.modeConfig?.guideType || "tentative";
    if (!this.indexedTargets) {
      this.indexedTargets = createSnapTargets(props.modeConfig.geoJson, []);
    }

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };
    const clickSequence = this.getClickSequence();

    if (clickSequence.length === 0 || !props.lastPointerMoveEvent) {
      const [firstPointSnapped, ...targets] = getFirstPointSnapped(props, this);
      this.firstPoint = firstPointSnapped;

      if (targets?.length > 0) {
        guides.features.push(...targets);
      }

      return guides;
    }

    const [mainFeature, ...otherFeatures] = createTentativeFeature(
      props,
      this,
      guideType
    );

    if (props?.modeConfig?.modeId === MODES_IDS.merge) {
      // mainFeature.properties.color = [100, 100, 0];
    }
    if (props?.modeConfig?.modeId === MODES_IDS.hole) {
      // mainFeature.properties.color = [255, 0, 0];
    }

    this.mainFeature = mainFeature;
    guides.features.push(mainFeature);

    guides.features = guides.features.concat(
      getEditHandlesForFeature(mainFeature, -1)
    );

    // Slice off the handles that are are next to the pointer
    if (clickSequence.length < 3) {
      guides.features = guides.features.slice(0, -1);
    }

    if (otherFeatures.length > 0) {
      guides.features.push(...otherFeatures);
    }

    return guides;
  };

  getModeTooltips = (props: any) => {};

  getTooltips = (props: any) => {
    const { modeConfig } = props;
    if (
      modeConfig?.guideType === GEOJSON_TYPES.markup &&
      !modeConfig?.showDimensionLine
    ) {
      return [];
    }

    const distanceTooltips = getDistanceTooltip(props, this.mainFeature, this);
    const stateTooltips = getStateTooltip(props, this);

    const modeTooltips: any = this.getModeTooltips(props);

    return [...distanceTooltips, ...stateTooltips, ...(modeTooltips || [])];
  };

  // interface, should be overidden in every mode to handle specific actions....
  handleAddFeature = (event: any, props: any) => {};

  handleDblClick = (event: any, props: any) => {
    this.stopAnimationLoop();
    this.handleAddFeature(event.sourceEvent, props);
  };

  handleClick = (event: any, props: any) => {
    this.startAnimationLoop();

    if (this._clickSequence.length === 0) {
      this._panState = getInitialCanvasState();
      this._enableEdgePanning(this.handlePointerMove.bind(this));
    }

    const { picks } = event;

    let hasBeenSet = false;
    const clickedEditHandle = getPickedEditHandle(picks);
    const isClosed = this.mainFeature?.properties?.closed;
    const isMultiLineMode = props?.modeConfig?.isMultiLineMode;
    const isSingleLineMode = props?.modeConfig?.isSingleLineMode;
    const is2PointsMode = [MODE_RECTANGLE, MODE_SPHERE].includes(
      this._tempArcMode
    );

    if (this._isArcMode) {
      const newSequence =
        this.mainFeature?.geometry?.type === GEOJSON_TYPES.Polygon
          ? this.mainFeature?.geometry?.coordinates[0]
          : this.mainFeature?.geometry?.coordinates;

      if (is2PointsMode) {
        if (this._isControlPoint) {
          this.setClickSequence(newSequence);
          this._arcs = this.mainFeature?.properties?.arcs || [];
          hasBeenSet = true;
          this.stopAnimationLoop();
          this.handleAddFeature(event, { ...props, is2PointsMode });
          return;
        } else {
          this._isControlPoint = true;
          this.dispatchEvent(0, newSequence?.length || 0);
        }
      }

      if (!is2PointsMode) {
        if (this._isControlPoint) {
          this.setClickSequence(newSequence);
          this._arcs = this.mainFeature?.properties?.arcs || [];
          this._isControlPoint = false;
          this._tempArcStartIndex = newSequence.length - 1;

          this._isArcMode = false;

          this.dispatchEvent(0, newSequence.length);

          return;
        } else if (newSequence && newSequence.length > 0) {
          this._isControlPoint = true;
        }
      }
    }

    if (isClosed) {
      hasBeenSet = true;
      this.setClickSequence(this.mainFeature?.geometry?.coordinates[0]);
    }

    if (!clickedEditHandle || isMultiLineMode) {
      const firstPointCoords =
        this?.firstPoint?.length && !this.mainFeature?.geometry
          ? this.firstPoint[0]
          : null;

      const coordinates = this.mainFeature?.geometry?.coordinates;
      const lastCoordinate =
        coordinates?.length > 0
          ? Array.isArray(last(coordinates[0]))
            ? last(coordinates[0])
            : last(coordinates)
          : null;

      const mapCoords = lastCoordinate || firstPointCoords || event.mapCoords;

      if (isSingleLineMode) {
        if (this._clickSequence.length > 0) {
          this._ignoreCLick = true;
          this.addClickSequence({ mapCoords });
          this.stopAnimationLoop();
          this.handleAddFeature(event, props);
          return;
        }
      }

      if (!this._ignoreCLick) {
        if (!hasBeenSet) {
          this.addClickSequence({ mapCoords });
        }

        this.dispatchEvent(
          !this._isArcMode
            ? DRAW_MODES_IDS.POLYGON
            : this._tempArcMode === MODE_RECTANGLE
            ? DRAW_MODES_IDS.RECTANGLE
            : this._tempArcMode === MODE_SPHERE
            ? DRAW_MODES_IDS.CIRCLE
            : DRAW_MODES_IDS.ARC,
          this.getClickSequence().length
        );
      }
    }
  };

  dispatchEvent = (state: number, sequenceLength: number) => {
    const event = new CustomEvent(CANVAS.POLYGON_MODE_CHANGE, {
      detail: {
        active: state,
        sequenceLength,
      },
    });

    window.dispatchEvent(event);
  };

  handleKeyDown = (event: any, props: any) => {
    const isSingleLineMode = props?.modeConfig?.isSingleLineMode;
    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;
    const clickSequence = this.getClickSequence();
    const previousIndices = this._arcs.flatMap((arc) => [
      arc.startPointIdx,
      ...arc.ignoreIndices,
    ]);

    // if (event.key === "h" || event.key === "H") {
    //   event.preventDefault();
    //   event.stopPropagation();

    //   this._isHeightMode = !this._isHeightMode;
    // }

    if (!isSingleLineMode) {
      if (event.key === "Tab") {
        event.preventDefault();
        event.stopPropagation();

        if (this._isArcMode) {
          if (
            [ARC_MODE_CIRCLE, ARC_MODE_QUADRATIC].includes(this._tempArcMode)
          ) {
            this._tempArcMode =
              this._tempArcMode === ARC_MODE_CIRCLE
                ? ARC_MODE_QUADRATIC
                : ARC_MODE_CIRCLE;
          }
        }
      }

      if (
        event.key === "r" ||
        event.key === "R" ||
        (ctrlOrCmd && (event.key === "R" || event.key === "r"))
      ) {
        event.preventDefault();
        event.stopPropagation();

        if (clickSequence.length < 2) {
          if (!this._isArcMode) {
            this._isArcMode = true;
            this._tempArcMode = MODE_RECTANGLE;
            this._isControlPoint = clickSequence.length === 0 ? false : true;

            this.dispatchEvent(
              DRAW_MODES_IDS.RECTANGLE,
              clickSequence?.length || 0
            );
          } else {
            if (this._tempArcMode === MODE_RECTANGLE) {
              this._isArcMode = false;
              this._isControlPoint = false;

              this.dispatchEvent(
                DRAW_MODES_IDS.POLYGON,
                clickSequence?.length || 0
              );
            } else {
              this._tempArcMode = MODE_RECTANGLE;
              this._isControlPoint = clickSequence.length === 0 ? false : true;

              this.dispatchEvent(
                DRAW_MODES_IDS.RECTANGLE,
                clickSequence?.length || 0
              );
            }
          }
        }
      }

      if (
        event.key === "c" ||
        event.key === "C" ||
        (ctrlOrCmd && (event.key === "C" || event.key === "c"))
      ) {
        event.preventDefault();
        event.stopPropagation();

        if (clickSequence.length < 2) {
          if (!this._isArcMode) {
            this._isArcMode = true;
            this._tempArcMode = MODE_SPHERE;
            this._isControlPoint = clickSequence.length === 0 ? false : true;

            this.dispatchEvent(
              DRAW_MODES_IDS.CIRCLE,
              clickSequence?.length || 0
            );
          } else {
            if (this._tempArcMode === MODE_SPHERE) {
              this._isArcMode = false;
              this._isControlPoint = clickSequence.length === 0 ? false : true;

              this.dispatchEvent(
                DRAW_MODES_IDS.POLYGON,
                clickSequence?.length || 0
              );
            } else {
              this._tempArcMode = MODE_SPHERE;
              this._isControlPoint = clickSequence.length === 0 ? false : true;

              this.dispatchEvent(
                DRAW_MODES_IDS.CIRCLE,
                clickSequence?.length || 0
              );
            }
          }
        }
      }

      if (
        event.key === "a" ||
        event.key === "A" ||
        (ctrlOrCmd && (event.key === "A" || event.key === "a"))
      ) {
        event.preventDefault();
        event.stopPropagation();

        if (!this._isArcMode) {
          if (!previousIndices.includes(clickSequence.length - 1)) {
            if (
              ![ARC_MODE_CIRCLE, ARC_MODE_QUADRATIC].includes(this._tempArcMode)
            ) {
              this._tempArcMode = ARC_MODE_CIRCLE;
            }

            this._isArcMode = true;
            this._tempArcStartIndex = clickSequence.length - 1;
            this._isControlPoint = false;

            this.dispatchEvent(DRAW_MODES_IDS.ARC, clickSequence?.length || 0);
          }
        } else {
          if (
            [ARC_MODE_CIRCLE, ARC_MODE_QUADRATIC].includes(this._tempArcMode)
          ) {
            this._isArcMode = false;
            this._tempArcStartIndex = -1;
            this._isControlPoint = false;
            this._tempArcMode = ARC_MODE_CIRCLE;

            this.dispatchEvent(
              DRAW_MODES_IDS.POLYGON,
              clickSequence?.length || 0
            );
          } else {
            this._isArcMode = true;
            this._tempArcMode = ARC_MODE_CIRCLE;
            this._isControlPoint = false;
            this._tempArcStartIndex = clickSequence.length - 1;

            this.dispatchEvent(DRAW_MODES_IDS.ARC, clickSequence?.length || 0);
          }
        }
      }
    }

    if (event.key === "Escape") {
      this.stopAnimationLoop();
      if (this._isArcMode) {
        event.preventDefault();
        event.stopPropagation();

        this._isArcMode = false;

        this.dispatchEvent(DRAW_MODES_IDS.POLYGON, clickSequence?.length || 0);
      } else {
        event.preventDefault();
        event.stopPropagation();

        this._resetState();

        if (clickSequence?.length === 0) {
          props.modeConfig.setDefaultMode();
        }
        props?.modeConfig?.setActiveClassification?.(null);
      }
    }
  };

  handleKeyUp = (event: any, props: any) => {
    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;
    const previousIndices = this._arcs.flatMap((arc) => [
      arc.startPointIdx,
      ...arc.ignoreIndices,
      arc.endPointIdx,
    ]);

    if (event.key === "Enter") {
      this.stopAnimationLoop();
      this._disableEdgePanning();
      this.handleAddFeature(event, props);
    }

    if (
      event.key === "z" ||
      event.key === "Z" ||
      (ctrlOrCmd && (event.key === "Z" || event.key === "z"))
    ) {
      event.preventDefault();
      const clickSequence = this.getClickSequence();

      if (clickSequence.length > 1) {
        const previousIndex = clickSequence.length - 1;

        if (previousIndices.includes(previousIndex)) {
          const arc = this._arcs.find((arc) =>
            [arc.endPointIdx, arc.startPointIdx, ...arc.ignoreIndices].includes(
              previousIndex
            )
          );
          if (arc) {
            const newSequence = clickSequence.slice(0, arc.startPointIdx + 1);
            this._arcs = this._arcs.filter((a) => a.id !== arc.id);
            this.setClickSequence(newSequence);

            this.dispatchEvent(0, newSequence?.length - 1);

            return;
          }
        }

        this.setClickSequence(clickSequence.slice(0, -1));

        this.dispatchEvent(0, clickSequence?.length - 1);
      } else {
        this.stopAnimationLoop();
        this.resetClickSequence();
        this._disableEdgePanning();

        this.dispatchEvent(0, 0);
        this._resetState();
      }
    }
  };

  handleModeChange = (event: any) => {
    const clickSequence = this.getClickSequence();
    switch (event.detail.id) {
      case DRAW_MODES_IDS.POLYGON:
        this._isArcMode = false;
        this._isControlPoint = false;
        this._tempArcMode = ARC_MODE_CIRCLE;
        break;
      case DRAW_MODES_IDS.RECTANGLE:
        this._isArcMode = true;
        this._isControlPoint = clickSequence?.length > 0 ? true : false;
        this._tempArcMode = MODE_RECTANGLE;
        break;
      case DRAW_MODES_IDS.CIRCLE:
        this._isArcMode = true;
        this._isControlPoint = clickSequence?.length > 0 ? true : false;
        this._tempArcMode = MODE_SPHERE;
        break;
      case DRAW_MODES_IDS.ARC:
        this._isArcMode = true;
        this._isControlPoint = false;
        this._tempArcMode = ARC_MODE_CIRCLE;
        break;
      default:
        break;
    }
  };

  handlePointerMove = (event: EditableLayerDraggingEvent, props: any) => {
    const { deck, useCanvasPan, viewState, setViewState } = props.modeConfig;
    const deckContext = deck?.current?.deck;

    if (!event.passive && this._clickSequence.length > 0) {
      this._updateEdgePanning(event, props);
    }

    if (this._panState && !useCanvasPan && !!deckContext) {
      const panProps = getPanningVector(event, this._panState, deckContext);

      if (panProps.shouldUpdate) {
        setViewState({
          ...viewState,
          zoom: panProps.zoom,
          target: panProps.target,
          transitionDuration: 5,
          transitionInterpolator: new LinearInterpolator(["target", "zoom"]),
        });
      } else {
        this._panAnimationProps = null;
      }
    }

    props.onUpdateCursor("crosshair");
  };

  animate() {
    // if (!!this._panAnimationProps) {
    //   const { deckContext, panX, panY, viewState, setViewState, ...rest } =
    //     this._panAnimationProps;
    //   const viewport = deckContext?.viewManager?._viewports[0];
    //   const zoom = viewport ? viewport.zoom : 0;
    //   let targetX = viewport.position[0];
    //   let targetY = viewport.position[1];
    //   const target = [targetX + panX, targetY + panY, 0];
    //   setViewState({
    //     ...viewState,
    //     zoom,
    //     target,
    //     transitionDuration: 5,
    //     transitionInterpolator: new LinearInterpolator(["target"]),
    //   });
    // }
  }
}
