import { React, useCallback, useEffect, useState } from "react";
import localForage from "localforage";

import { parseJwt } from "sf/utils/jwt";

import * as hubSpotApi from "src/lib/api/hubspot";
import { useAuth } from "src/modules/Auth/context";

import SupportLinksMenu from "./components";

export default function HubSpot() {
  const { user } = useAuth();

  const [token, setToken] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getHubSpotToken = useCallback(async () => {
    const storageKey = `hubspot-token-${user?.id}`;

    try {
      const storedToken = await localForage.getItem(storageKey);

      const expiryTimeStamp = storedToken && parseJwt(storedToken).exp * 1000;
      if (!storedToken || !expiryTimeStamp || expiryTimeStamp < Date.now()) {
        const result = await hubSpotApi.get();

        await localForage.setItem(storageKey, result.token);

        setToken(result.token);
      } else {
        setToken(storedToken);
      }
    } catch (error) {
      console.error(`Unable to obtain HubSpot token`, error);

      if (token !== null) {
        setToken(null);
      }
    }
  }, [user]);

  useEffect(() => {
    getHubSpotToken();
  }, [user]);

  useEffect(() => {
    if (!isLoaded || !window?.HubSpotConversations?.widget?.load) return;

    if (token) {
      window.hsConversationsSettings = {
        identificationEmail: user?.email || "",
        identificationToken: token,
      };
      window.HubSpotConversations.widget.load();
    }
  }, [token, isLoaded]);

  useEffect(() => {
    if (isLoaded) return;

    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    const script = window.document.createElement("script");

    window.document.head.append(script);

    // https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
    window.hsConversationsOnReady = [
      () => {
        setIsLoaded(true);
      },
    ];

    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/9162453.js";
  }, [isLoaded]);

  return <SupportLinksMenu />;
}
