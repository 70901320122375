import { selector } from "recoil";
import localforage from "localforage";

export const MAP_STORE_KEY = "_keys";
export const HIDDEN_STORE_KEY = "hidden";
export const EXPANDED_FOLDERS_STORE_KEY = "expanded_folders";

export function localStorageStoreHelper(storeKey, store, defaultValue) {
  return selector({
    key: storeKey + "withStorage",
    get: ({ get }) => {
      const current = get(store);

      if (current !== undefined) {
        return current;
      } else {
        try {
          const value = localStorage.getItem(storeKey);
          if (value) {
            const parsed = JSON.parse(value);
            return parsed;
          } else {
            return defaultValue;
          }
        } catch (e) {
          console.error("localStorageStoreHelper E : ", e);
          return defaultValue;
        }
      }
    },
    set: ({ set }, value) => {
      set(store, value);
      try {
        localStorage.setItem(storeKey, JSON.stringify(value));
      } catch (e) {
        console.error("localStorageStoreHelper E : ", e);
      }
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

// helper to get detailer type of variable, exmaple "Map, Set, Array, Object, etc."
const getType = (variable) =>
  Object.prototype.toString.call(variable).slice(8, -1) ?? "unknown";

export function localForageStoreHelper(
  storeKey,
  store,
  defaultValue,
  parentIdStore
) {
  return selector({
    key: storeKey + "withStorage",
    get: async ({ get }) => {
      const current = get(store);
      const parentId = get(parentIdStore);

      const defaultValueType = getType(defaultValue);
      const currentType = getType(current?.data);

      if (!parentId) {
        return defaultValue;
      }

      if (current?.parentId === parentId && defaultValueType === currentType) {
        return current.data;
      } else {
        const cache = await getLocalStorageValue(storeKey, parentId);
        const dataType = getType(cache?.data);

        if (cache?.data && dataType === defaultValueType) {
          return cache.data;
        }
        return defaultValue;
      }
    },
    set: ({ set, get }, value) => {
      const parentId = get(parentIdStore);

      if (parentId) {
        const newValue = {
          data: value,
          parentId: parentId,
          lastSynced: new Date(),
        };

        set(store, newValue);
        updateLocalStorageValue(storeKey, parentId, newValue);
      }
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

async function getLocalStorageValue(storeKey, parentId) {
  const itemKey = storeKey + "_" + parentId;

  try {
    const value = await localforage.getItem(itemKey);
    return value;
  } catch (e) {
    console.warn("localforage getLocalItem E : ", e);
    return null;
  }
}

export async function getStorageMapValue(key) {
  const storeStateKey = key + MAP_STORE_KEY;

  try {
    const value = await localforage.getItem(storeStateKey);
    return value;
  } catch (e) {
    console.warn("localforage getLocalItem E : ", e);
    return null;
  }
}
export async function updateStorageMapValue(key, value) {
  const storeStateKey = key + MAP_STORE_KEY;
  try {
    await localforage.setItem(storeStateKey, value);
  } catch (e) {
    console.warn("localforage setLocalItem E : ", e);
  }
}

async function updateLocalStorageValue(storeKey, parentId, value) {
  const storeStateKey = storeKey + MAP_STORE_KEY;
  const itemKey = storeKey + "_" + parentId;

  let storeState = null;

  try {
    storeState = await localforage.getItem(storeStateKey);
  } catch (e) {
    console.warn("localforage getLocalItem E : ", e);
  }

  storeState = new Map();

  if (!storeState) {
    storeState.set(itemKey, value.lastSynced);
  } else {
    storeState.set(itemKey, value.lastSynced);
  }

  try {
    await localforage.setItem(itemKey, value);
    await localforage.setItem(storeStateKey, storeState);
  } catch (e) {
    console.warn("localforage setLocalItem E : ", e);
  }
}

async function removeItemWithKey(key) {
  try {
    await localforage.removeItem(key);
  } catch (e) {
    console.warn("localforage removeItem E : ", e);
  }
}

export async function clearOldTempCache(key) {
  const state = await getStorageMapValue(key);

  if (state && state instanceof Map) {
    const newState = new Map(state);
    let hasUpdated = false;

    state.forEach((value, key) => {
      const daysDiff = Math.floor(
        (new Date() - new Date(value)) / (1000 * 60 * 60 * 24)
      );
      if (daysDiff > 4) {
        hasUpdated = true;
        removeItemWithKey(key);
        newState.delete(key);
      }
    });

    if (hasUpdated) {
      updateStorageMapValue(newState);
    }
  }
}
