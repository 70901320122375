import turfUnion from "@turf/union";

import { distance3d } from "./modes";
import { generateId } from "./string";
import { RGB_COLORS } from "../consts/style";
import { GEOJSON_TYPES } from "../consts/editor";
import { getAngleBetween3d, getCleanPoint } from "./coordinates";
import {
  getPipelineWidth,
  pipelineToPolygon,
  getPipelineWidthReal,
} from "./pipeline";
import {
  mergeGeometries,
  getPipelineTubeGeometries,
} from "../modes/3drendering/utils";
import {
  getJointDetails,
  JOINT_TYPES,
  JointDetails,
} from "./pipeline_joints_validations";

/**
 *
 *
 * 2d joint generation
 *
 *
 */

const JOINT_LENGTH = 2.5;
const JOINT_WIDTH = 1.2;

function bezierPoint(
  p0: [number, number, number],
  p1: [number, number, number],
  p2: [number, number, number],
  p3: [number, number, number],
  t: number
): [number, number, number] {
  const oneMinusT = 1 - t;
  const oneMinusTSquared = oneMinusT * oneMinusT;
  const oneMinusTCubed = oneMinusTSquared * oneMinusT;
  const tSquared = t * t;
  const tCubed = tSquared * t;

  return [
    oneMinusTCubed * p0[0] +
      3 * oneMinusTSquared * t * p1[0] +
      3 * oneMinusT * tSquared * p2[0] +
      tCubed * p3[0],
    oneMinusTCubed * p0[1] +
      3 * oneMinusTSquared * t * p1[1] +
      3 * oneMinusT * tSquared * p2[1] +
      tCubed * p3[1],
    oneMinusTCubed * p0[2] +
      3 * oneMinusTSquared * t * p1[2] +
      3 * oneMinusT * tSquared * p2[2] +
      tCubed * p3[2],
  ];
}

function smoothLine(
  points: [number, number, number][],
  smoothIndex: number,
  percent: number,
  numPoints: number = 5
) {
  if (points.length < 3) return points;
  if (smoothIndex <= 0 || smoothIndex >= points.length - 1) {
    return points;
  }
  const smoothPercent = Math.max(0, Math.min(100, percent)) / 100;

  const prev = points[smoothIndex - 1];
  const curr = points[smoothIndex];
  const next = points[smoothIndex + 1];

  const v1 = [curr[0] - prev[0], curr[1] - prev[1], curr[2] - prev[2]];
  const v2 = [next[0] - curr[0], next[1] - curr[1], next[2] - curr[2]];

  const v1Len = Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1] + v1[2] * v1[2]);
  const v2Len = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1] + v2[2] * v2[2]);

  const v1Norm = [v1[0] / v1Len, v1[1] / v1Len, v1[2] / v1Len];
  const v2Norm = [v2[0] / v2Len, v2[1] / v2Len, v2[2] / v2Len];

  const dist1 = v1Len * smoothPercent;
  const dist2 = v2Len * smoothPercent;

  const p0: [number, number, number] = [
    curr[0] - v1Norm[0] * dist1,
    curr[1] - v1Norm[1] * dist1,
    curr[2] - v1Norm[2] * dist1,
  ];

  const blendFactor = Math.min(0.5, smoothPercent);
  const p1: [number, number, number] = [
    curr[0] - v1Norm[0] * dist1 * blendFactor,
    curr[1] - v1Norm[1] * dist1 * blendFactor,
    curr[2] - v1Norm[2] * dist1 * blendFactor,
  ];

  const p2: [number, number, number] = [
    curr[0] + v2Norm[0] * dist2 * blendFactor,
    curr[1] + v2Norm[1] * dist2 * blendFactor,
    curr[2] + v2Norm[2] * dist2 * blendFactor,
  ];

  const p3: [number, number, number] = [
    curr[0] + v2Norm[0] * dist2,
    curr[1] + v2Norm[1] * dist2,
    curr[2] + v2Norm[2] * dist2,
  ];

  const curvePoints = [];
  for (let i = 0; i <= numPoints; i++) {
    const t = i / numPoints;
    const point = bezierPoint(p0, p1, p2, p3, t);
    curvePoints.push(point);
  }

  return [
    ...points.slice(0, smoothIndex),
    ...curvePoints,
    ...points.slice(smoothIndex + 1),
  ];
}

function getPointAtDistance(
  p1: [number, number, number],
  p2: [number, number, number],
  distance: number
): [number, number, number] {
  const lineDistance = distance3d(p1[0], p1[1], p1[2], p2[0], p2[1], p2[2]);
  const ratio = distance / lineDistance;
  return [
    p1[0] + (p2[0] - p1[0]) * ratio,
    p1[1] + (p2[1] - p1[1]) * ratio,
    p1[2] + (p2[2] - p1[2]) * ratio,
  ];
}

function lineCoordsToPolygonCoords(lineCoords: any[], width: number) {
  const line = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: lineCoords,
    },
    properties: {},
  };

  return pipelineToPolygon(line, new Map(), 1, width).properties.linePolygon;
}

function getOtherPipelinesNeighbours(
  pipelines: any[],
  id: string,
  key: string
) {
  const neighbours = [];
  const pipelineIds = [];
  let isVertical = false;

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    if (pipeline.properties.id !== id) {
      const coordinates = pipeline.geometry.coordinates;

      for (let j = 0; j < coordinates.length; j++) {
        const point = [
          coordinates[j][0],
          coordinates[j][1],
          coordinates[j][2] || 0,
        ];
        const pointKey = `${point[0]}_${point[1]}_${point[2]}`;
        if (key === pointKey) {
          if (pipeline?.properties?.isVertical) {
            isVertical = true;
          }
          pipelineIds.push(pipeline.properties.id);

          if (j > 0) {
            neighbours.push([
              coordinates[j - 1],
              pipeline.properties.pipelineWidth,
              pipeline.properties.isVertical,
              pipeline.properties.id,
            ]);
          }
          if (j < coordinates.length - 1) {
            neighbours.push([
              coordinates[j + 1],
              pipeline.properties.pipelineWidth,
              pipeline.properties.isVertical,
              pipeline.properties.id,
            ]);
          }
        }
      }
    }
  }

  return {
    neighbours,
    pipelineIds,
    isVertical,
  };
}

export function getSharedPoints(pipelines: any[]) {
  const sharedPoints = new Map();
  const sharedPointsDetails = new Map();

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    const coordinates = pipeline.geometry.coordinates;

    for (let j = 0; j < coordinates.length; j++) {
      const point = [
        coordinates[j][0],
        coordinates[j][1],
        coordinates[j][2] || 0,
      ];
      const key = `${point[0]}_${point[1]}_${point[2]}`;
      const pointNeighbours = [];

      if (!sharedPoints.has(key)) {
        if (j > 0) {
          pointNeighbours.push([
            coordinates[j - 1],
            pipeline.properties.pipelineWidth,
            pipeline.properties.isVertical,
            pipeline.properties.id,
          ]);
        }
        if (j < coordinates.length - 1) {
          pointNeighbours.push([
            coordinates[j + 1],
            pipeline.properties.pipelineWidth,
            pipeline.properties.isVertical,
            pipeline.properties.id,
          ]);
        }

        const { neighbours, pipelineIds, isVertical } =
          getOtherPipelinesNeighbours(pipelines, pipeline.properties.id, key);
        pointNeighbours.push(...neighbours);

        sharedPoints.set(key, pointNeighbours);
        sharedPointsDetails.set(key, {
          controlPoint: point,
          neighbours: pointNeighbours.map((n) => [
            getCleanPoint(n[0]),
            ...n.slice(1),
          ]),
          isVertical: isVertical || pipeline?.properties?.isVertical,
          pipelineIds: new Set([...pipelineIds, pipeline.properties.id]),
        });
      }
    }
  }

  return sharedPointsDetails;
}

function getJointEndingsPolygons(
  controlPoint: [number, number, number],
  coordinates: [number, number, number],
  realWidth: number,
  referenceInchesPerPixels: number,
  jointLinesConstruction: any[],
  maxPipelineWidth: number,
  typePrefix: string = "main"
) {
  const pipelineWidth = getPipelineWidthReal(
    realWidth * 1.25,
    referenceInchesPerPixels
  );
  // can be set as a param later,
  const sections = [100];
  const length = realWidth * 1.5;

  const endingSectionsPoints = [];

  for (const section of sections) {
    const point = getPointAtDistance(
      controlPoint,
      coordinates,
      (length * section) / 100
    );
    endingSectionsPoints.push(point);
  }

  const endingPolygons = [];

  for (let i = 0; i < endingSectionsPoints.length; i++) {
    const endingSectionCoords = endingSectionsPoints[i];
    const previousPointCoords = endingSectionsPoints[i - 1] || controlPoint;

    jointLinesConstruction.push({
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.LineString,
        coordinates: [previousPointCoords, endingSectionCoords],
      },
      properties: {
        type: `${typePrefix}_ending`,
        pipelineWidth,
        parentWidth: maxPipelineWidth,
      },
    });

    endingPolygons.push(
      lineCoordsToPolygonCoords(
        [previousPointCoords, endingSectionCoords],
        realWidth * 1.25
      )
    );
  }

  return endingPolygons;
}

function generatePTap(
  sharedPointDetails: any,
  jointDetails: JointDetails,
  referenceInchesPerPixels: number
): any {
  const { neighbours, controlPoint } = sharedPointDetails;
  const length = getPipelineWidth(10, referenceInchesPerPixels);
  const upLength = getPipelineWidth(3, referenceInchesPerPixels);
  const endLength = getPipelineWidth(4, referenceInchesPerPixels);
  const paddingLength = getPipelineWidth(2, referenceInchesPerPixels);

  const verticalJoint = neighbours.find((n: any) => n[2] === true);
  const horizontalJoint = neighbours.find((n: any) => n[2] === false);

  const jointLinesConstruction: any[] = [];

  const cpUp = getPointAtDistance(controlPoint, verticalJoint[0], upLength);

  const cp1 = getPointAtDistance(controlPoint, horizontalJoint[0], length);
  const cp1Adjusted: [number, number, number] = [
    cp1[0],
    cp1[1],
    cp1[2] + paddingLength,
  ];

  const cpEnd = getPointAtDistance(
    controlPoint,
    horizontalJoint[0],
    length + endLength
  );

  const cp2 = getPointAtDistance(cp1, controlPoint, length / 2);
  const cp2Adjusted: [number, number, number] = [
    cp2[0],
    cp2[1],
    cp2[2] + length * 0.8,
  ];

  const jointLineCoords = [controlPoint, cp2Adjusted, cp1Adjusted];
  const jointLineCoordsEnd = [cp1Adjusted, cp1, cpEnd];

  const smoothedJointLineCoords = [
    cpUp,
    controlPoint,
    ...smoothLine(jointLineCoords, 1, 99, 25),
    ...smoothLine(jointLineCoordsEnd, 1, 20, 10),
  ];

  const jointLinePolygon = lineCoordsToPolygonCoords(
    smoothedJointLineCoords,
    length * 2
  );

  let joint = jointLinePolygon;
  joint = turfUnion(joint, jointLinePolygon);

  jointLinesConstruction.push({
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: smoothedJointLineCoords,
    },
    properties: {
      type: "mainJoint",
      pipelineWidth: 2.2,
      parentWidth: 2,
    },
  });

  const jointEndingsPolygons: any[] = [];

  return joint
    ? {
        ...joint,
        geometry: {
          type: GEOJSON_TYPES.MultiPolygon,
          coordinates: [
            joint.geometry.coordinates,
            // ...jointEndingsPolygons.map((j) => j.geometry.coordinates),
          ],
        },
        properties: {
          ...joint.properties,
          id: generateId(),
          opacity: 255,
          jointLinesConstruction,
          color: RGB_COLORS.PIPELINE,
          borderColor: RGB_COLORS.DARKJOINT,
        },
      }
    : null;
}

export function generateJoint(
  sharedPointDetails: any,
  jointDetails: JointDetails,
  referenceInchesPerPixels: number
) {
  const isPTap = jointDetails.type === JOINT_TYPES.P_TAP;
  if (isPTap) {
    return generatePTap(
      sharedPointDetails,
      jointDetails,
      referenceInchesPerPixels
    );
  }

  const isFlushBushing = jointDetails.type === JOINT_TYPES.FLUSH_BUSHING;

  const { neighbours, controlPoint } = sharedPointDetails;
  const { mainJoints, secondaryJoints } = jointDetails;

  const jointLinesConstruction: any[] = [];

  const jointEndingsPolygons: any[] = [];

  const maxPipelineWidth = neighbours.reduce(
    (acc: number, n: any) => Math.max(acc, n[1] || 0),
    0
  );

  const maxPipelineWidthReal = getPipelineWidth(
    maxPipelineWidth || 0,
    referenceInchesPerPixels
  );

  const jointLength = isFlushBushing
    ? maxPipelineWidthReal / JOINT_LENGTH
    : maxPipelineWidthReal * JOINT_LENGTH;

  const cp1 = getPointAtDistance(controlPoint, mainJoints[0][0], jointLength);
  const cp2 = getPointAtDistance(controlPoint, mainJoints[1][0], jointLength);

  const jointLineCoords = [cp1, controlPoint, cp2];

  const angle = Math.round(getAngleBetween3d(cp1, controlPoint, cp2));
  const shouldNotSmooth = angle > 175 && angle < 185;

  const smoothedJointLineCoords = shouldNotSmooth
    ? jointLineCoords
    : smoothLine(jointLineCoords, 1, 15, 10);

  jointLinesConstruction.push({
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: smoothedJointLineCoords,
    },
    properties: {
      type: "mainJoint",
      pipelineWidth: isFlushBushing
        ? maxPipelineWidth * JOINT_LENGTH
        : maxPipelineWidth * JOINT_WIDTH,
      parentWidth: maxPipelineWidth,
    },
  });

  const jointPolygonWidth = isFlushBushing
    ? maxPipelineWidthReal * JOINT_LENGTH
    : maxPipelineWidthReal * JOINT_WIDTH;
  const jointLinePolygon = lineCoordsToPolygonCoords(
    smoothedJointLineCoords,
    jointPolygonWidth
  );

  if (isFlushBushing) {
    const cp1_fb = getPointAtDistance(
      controlPoint,
      mainJoints[0][0],
      jointLength * 2
    );
    const cp2_fb = getPointAtDistance(
      controlPoint,
      mainJoints[1][0],
      jointLength * 2
    );
    const jointLinePolygon_fb = lineCoordsToPolygonCoords(
      [cp1_fb, controlPoint, cp2_fb],
      jointPolygonWidth / 2
    );

    jointLinesConstruction.push({
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.LineString,
        coordinates: [cp1_fb, controlPoint, cp2_fb],
      },
      properties: {
        type: "mainJoint",
        pipelineWidth: maxPipelineWidth * JOINT_WIDTH,
        parentWidth: maxPipelineWidth,
      },
    });

    jointEndingsPolygons.push(jointLinePolygon_fb);
  } else {
    jointEndingsPolygons.push(
      ...getJointEndingsPolygons(
        cp1,
        mainJoints[0][0],
        maxPipelineWidthReal,
        referenceInchesPerPixels,
        jointLinesConstruction,
        maxPipelineWidth
      )
    );

    jointEndingsPolygons.push(
      ...getJointEndingsPolygons(
        cp2,
        mainJoints[1][0],
        maxPipelineWidthReal,
        referenceInchesPerPixels,
        jointLinesConstruction,
        maxPipelineWidth
      )
    );
  }

  let joint = jointLinePolygon;
  joint = turfUnion(joint, jointLinePolygon);

  secondaryJoints.forEach((neighbour) => {
    const width = neighbour[1] || 0;
    const widthReal = getPipelineWidth(width, referenceInchesPerPixels);

    const jointLength = widthReal * JOINT_LENGTH * 1.3;
    const sp1 = getPointAtDistance(controlPoint, neighbour[0], jointLength);

    const lineCoords = [sp1, controlPoint];
    const secondaryLineCoords = jointDetails?.isSmooth
      ? smoothLine([sp1, controlPoint, cp1], 1, 80, 10)
      : lineCoords;

    const secondaryLinePolygon = lineCoordsToPolygonCoords(
      secondaryLineCoords,
      widthReal * JOINT_WIDTH
    );

    jointLinesConstruction.push({
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.LineString,
        coordinates: secondaryLineCoords,
      },
      properties: {
        type: "secondaryJoint",
        pipelineWidth: width * JOINT_WIDTH,
        parentWidth: maxPipelineWidth,
      },
    });

    jointEndingsPolygons.push(
      ...getJointEndingsPolygons(
        sp1,
        neighbour[0],
        widthReal,
        referenceInchesPerPixels,
        jointLinesConstruction,
        maxPipelineWidth,
        "secondary"
      )
    );

    joint = turfUnion(joint, secondaryLinePolygon);
  });

  return joint
    ? {
        ...joint,
        geometry: {
          type: GEOJSON_TYPES.MultiPolygon,
          coordinates: [
            joint.geometry.coordinates,
            ...jointEndingsPolygons.map((j) => j.geometry.coordinates),
          ],
        },
        properties: {
          ...joint.properties,
          id: generateId(),
          opacity: 255,
          jointLinesConstruction,
          color: RGB_COLORS.PIPELINE,
          borderColor: RGB_COLORS.DARKJOINT,
        },
      }
    : null;
}

export function generateJoints(
  pipelines: any[],
  fixtures: any[],
  referenceInchesPerPixels: number
) {
  const sharedPoints = getSharedPoints(pipelines);
  const joints: any = [];

  sharedPoints.forEach((sharedPointDetails, key) => {
    const jointDetails = getJointDetails(sharedPointDetails, fixtures);

    if (jointDetails) {
      const joint = generateJoint(
        sharedPointDetails,
        jointDetails,
        referenceInchesPerPixels
      );

      if (joint) {
        joints.push(joint);
      }
    }
  });

  return joints;
}

export async function generateJoints3dGeometry(
  joints: any[],
  referenceInchesPerPixels: number
) {
  const jointsAsPipelines = joints.flatMap(
    (j) => j.properties.jointLinesConstruction
  );
  if (!jointsAsPipelines || jointsAsPipelines.length === 0) return null;

  const tubes: any[] = [];

  for (const joint of jointsAsPipelines) {
    const width = getPipelineWidth(
      joint?.properties?.pipelineWidth,
      referenceInchesPerPixels
    );

    const pipelineGeometry = await getPipelineTubeGeometries(joint, width, 0);
    tubes.push(pipelineGeometry);
  }
  const finalGeometry = mergeGeometries(tubes);
  return finalGeometry;
}
