import center from "@turf/center";

import {
  getViewReq,
  createView,
  getPageViews,
  fetchEditorSet,
  listEditorPage,
  prepareBreakdowns,
  getSpecifiPageView,
  fetchEditorProject,
  saveAtomicOperations,
  prepareSetClassifications,
} from "./api";
import {
  createSearchIndex,
  rotatePageFeatures,
  cleanDedupedFeatures,
  flattenClassifications,
  getClassificationsByID,
  updateClassificationById,
  getDefaultClassification,
  cleanDefaultClassificationUnits,
  getClassificationsByIDWihDefaults,
} from "./utils";

import { DEFAULT_QUANTITY_ROUND } from "./constants";
import { LABELLER_ROLE, LABELLING_ORG } from "src/lib/cvat";
import { reconstructBreakdowns } from "./BreakdownPanel/utils";
import { scaleUpFeature } from "src/UtilComponents/DataManager/dataUtils";
import { PAGE_TEXT_SEARCH_INDEX } from "src/UtilComponents/DataManager/const";

import {
  UNITS,
  TAKE_OFFS,
  CLS_COLORS,
  DEFAULT_SHAPE,
  DEFAULT_COLOR,
  GEOJSON_TYPES,
  TAKE_OFF_TYPES,
  DEFAULT_LINE_ID,
  DEFAULT_AREA_ID,
  DEFAULT_COUNT_ID,
  DEFAULT_DOORS_ID,
  DOORS_POLYGON_ID,
  DEFAULT_REGION_ID,
  DEFAULT_ROOM_ID_ML,
  DEFAULT_OCR_FOLDER,
  DEFAULT_ML_AREA_ID,
  DEFAULT_ML_WALL_ID,
  DEFAULT_ML_COUNT_ID,
  DOORS_CENTERLINE_ID,
  DEFAULT_FOLDERS_MAP,
  DEFAULT_COUNT_ID_ML,
  DEFAULT_WALLS_ID_ML,
  DEFAULT_REGION_ID_ML,
  DEFAULT_FOOTPRINT_ID,
  CLASSIFICATION_TYPES,
  DEFAULT_FOLDER_OCR_ID,
  DEFAULT_DOORS_LINE_ID,
  DEFAULT_FOLDERS_IDS_SET,
  CLASSIFICATION_CATEGORIES,
  DEFAULT_LINE_PERIMETER_ID,
  DEFAULT_CLASSIFICATION_MAP,
  DEFAULT_WALL_PERIMETER_TYPE,
  DEFAULT_CLASSIFICATION_IDS_SET,
  DEFAULT_SEARCH_RESULT_ID_TYPES,
  DEFAULT_UNASSIGNED_FEATURES_ID,
  DEFAULT_GROSS_INTERNAL_AREA_ID,
} from "sf/consts/editor";

import {
  UNIT_FEET,
  QUANTITIES,
  UNIT_METER,
  UNIT_INCHES,
  QUANTITY_AREA,
  QUANTITY_COUNT,
  QUANTITY_LENGTH,
  QUANTITY_PERIMETER,
} from "sf/consts/classifications";

import {
  getDrawingBounds,
  cleanCoordinates,
  cleanArrayIndentation,
  cleanPolygonsFeatures,
} from "sf/utils/coordinates";
import {
  calcZoom,
  getPolygonHoles,
  cleanMarkupFeatures,
  cleanAndPrepareFeatures,
  cleanFeaturesClassifications,
} from "sf/utils/editor";
import { generateId } from "sf/utils/string";
import { getUniqueArray } from "sf/utils/array";
import { cleanDuplicates, getSuggestion } from "sf/utils/data";
import { PATTERNS_DISPLAY } from "sf/components/Controller/consts";
import {
  VIEW_PREFIX,
  COMBINED_VIEW,
  SET_ROLE_OWNER,
  SET_ROLE_VIEWER,
  SET_ROLE_MANAGER,
} from "sf/permissions";
import { getFeatureMeasures } from "sf/modes/base/ImmutableLayersData";

// prepareSet used to initialize the Editor data (page data preparations, ColorMaps....)
export async function prepareSet(
  setId,
  userRole,
  userOrg,
  userId,
  orgId = null,
  pageId = null,
  defaultViewId = null
) {
  const isLabellingOrg = userOrg?.type === LABELLING_ORG;
  const isLabeler = isLabellingOrg && userRole === LABELLER_ROLE;

  const set = await fetchEditorSet(setId);
  const project = await fetchEditorProject(set?.project_id);
  const scale_units = project?.scale_units;
  const useMetrics = project?.scale_units === UNITS.si;

  const pagesQuery = await listEditorPage(setId, set?.organization_id, pageId);
  let page = pagesQuery?.rows?.find((p) => p.id === pageId);
  const rotation = page?.metadata?.rotateRad || 0;
  page = rotatePageFeatures(page, rotation);

  const pages = [page];
  set.page_ids = pages.map((p) => p.id);

  const { viewName, viewId } = getDefaultActiveView(
    set,
    pages,
    pageId,
    userId,
    isLabellingOrg
  );

  const setClassifications = await prepareSetClassifications(orgId, setId);
  let classification = prepareClassification(
    setClassifications,
    true,
    useMetrics
  );

  const classificationIds = new Set(classification.map((cls) => cls.id));

  const view_classification_order =
    set?.permissions?.set?.users &&
    userId in set?.permissions?.set?.users &&
    set?.permissions?.set?.users[userId]?.classification_order;

  const classification_order = view_classification_order ||
    set?.classification_order || [...classificationIds];

  const { view } = await getView(
    viewName,
    defaultViewId || viewId || null,
    set,
    pages,
    userId,
    pageId,
    orgId,
    classification,
    isLabellingOrg,
    classification_order
  );

  view.features = getPolygonHoles(
    cleanMarkupFeatures(
      cleanFeaturesClassifications(
        view?.features || [],
        getClassificationsByIDWihDefaults(classification)
      )
    )
  );

  const { deleteIds, cleanedFeatures } = cleanDedupedFeatures(
    view.features || []
  );
  if (deleteIds?.length > 0) {
    console.error("Deduped features found, cleaning...");
    saveAtomicOperations(view.id, cleanedFeatures, {
      deleteFeatures: deleteIds.map((id) => ({
        properties: { id },
      })),
    });

    view.features = cleanedFeatures;
  }

  classification = getClassificationsWIthDefaultFolders(
    classification,
    view.features,
    classification_order,
    useMetrics
  );

  const setBreakdowns = await prepareBreakdowns(orgId, setId);
  const breakdownsIds = new Set(setBreakdowns.map((br) => br.id));

  const view_breakdown_order =
    set?.permissions?.set?.users &&
    userId in set?.permissions?.set?.users &&
    set?.permissions?.set?.users[userId]?.breakdown_order;

  const breakdowns_order = view_breakdown_order ||
    set?.breakdown_order || [...breakdownsIds];

  const orderedBreakdowns = [
    ...breakdowns_order
      .filter((brId) => breakdownsIds.has(brId))
      .map((brId) => setBreakdowns.find((br) => br.id === brId)),
    ...setBreakdowns.filter((cls) => !breakdowns_order.includes(cls.id)),
  ];

  const breakdowns = reconstructBreakdowns(orderedBreakdowns);

  const textData = getPageTextFeatures(page);
  const pageSearchIndex = createSearchIndex(textData, {
    index: PAGE_TEXT_SEARCH_INDEX,
  });

  return {
    ...set,
    view,
    pages,
    isLabeler,
    useMetrics,
    breakdowns,
    scale_units,
    isLabellingOrg,
    classification,
    pageSearchIndex,
    classification_order,
    activeView: viewName,
    activeViewId: viewId,
  };
}

// preparePageData is Togal.ai specific, loops through Togal geojson data and clean it in a generic fashion
// the function cleanAndPrepareFeatures is the one responsible for a generic GEOJSON format cleaning and
// preparing to be used with the Editor
export function preparePageData(
  classifications,
  view,
  isLabellingOrg,
  classification_order = [],
  calculateNewFeatures = false,
  useMetrics = false
) {
  if (!view.page?.url) return { error: "No url provided for page!" };

  const tempFeatures = (view?.geojson?.features || []).filter(
    (f) =>
      [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon].includes(
        f?.geometry?.type
      ) &&
      f?.properties?.types?.includes(GEOJSON_TYPES.markup) &&
      (f?.properties?.types?.includes(DEFAULT_ROOM_ID_ML) ||
        f?.properties?.types?.includes(DEFAULT_REGION_ID_ML) ||
        f?.properties?.types?.includes(DOORS_POLYGON_ID))
  );
  const clMap = getClassificationsByID(
    classifications.concat(getOCRClassifications(tempFeatures))
  );

  const bounds = getDrawingBounds(
    [],
    view.page.url_width,
    view.page.url_height
  );
  const centerLatLng = [bounds[1][0] / 2, bounds[1][1] / 2];

  let features = [];

  const DEFAULT_OPTIONS = {
    clMap,
    bounds,
    forceNewID: false,
    autoClassify: true,
    dpi: view.page.url_dpi,
    width: view.page.url_width,
    scale_real: view.page.scale_real,
    scale_drawing: view.page.scale_drawing,
  };

  // Preparing/cleaning data for the default geojson_layers
  [
    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        autoClassify: false,
        defaultClass: DEFAULT_FOOTPRINT_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        (feature?.geometry?.type === GEOJSON_TYPES.Polygon ||
          feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) &&
        feature.properties.types.includes(DEFAULT_FOOTPRINT_ID),
      beforeHook: null,
      afterHook: (feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          className: feature.properties.className || DEFAULT_FOOTPRINT_ID,
        },
      }),
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        defaultClass: DEFAULT_REGION_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        (feature?.geometry?.type === GEOJSON_TYPES.Polygon ||
          feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) &&
        feature.properties.types.includes(DEFAULT_REGION_ID_ML) &&
        !feature.properties.types.includes(DOORS_POLYGON_ID) &&
        !feature.properties.types.includes(DEFAULT_FOOTPRINT_ID),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        autoClassify: false,
        defaultClass: DEFAULT_DOORS_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        (feature?.geometry?.type === GEOJSON_TYPES.Polygon ||
          feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) &&
        feature.properties.types.includes(DOORS_POLYGON_ID),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        defaultClass: DEFAULT_AREA_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        (feature?.geometry?.type === GEOJSON_TYPES.Polygon ||
          feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) &&
        !feature.properties.types.includes(DEFAULT_FOOTPRINT_ID) &&
        feature.properties.types.includes(DEFAULT_ROOM_ID_ML),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        defaultClass: DEFAULT_GROSS_INTERNAL_AREA_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        (feature?.geometry?.type === GEOJSON_TYPES.Polygon ||
          feature?.geometry?.type === GEOJSON_TYPES.MultiPolygon) &&
        feature.properties.types.includes(GEOJSON_TYPES.RoomGIA),
      beforeHook: null,
      afterHook: null,
    },

    // no wall polygon is ever returned, needs to be  added later
    // {
    //   data: view?.geojson_wall_polygons?.features || [],
    //   options: {
    //     ...DEFAULT_OPTIONS,
    //     // forceNewID: true,
    //     autoClassify: false,
    //     defaultClass: DEFAULT_LINE_POLYGON_ID,
    //     takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
    //   },
    //   dataFilter: (feature) => feature?.geometry,
    //   beforeHook: null,
    //   afterHook: null,
    // },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        defaultClass: DEFAULT_LINE_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        feature.properties.types.includes(DEFAULT_WALLS_ID_ML) &&
        !feature.properties.types.includes(DOORS_CENTERLINE_ID) &&
        (feature.geometry.type === GEOJSON_TYPES.MultiLineString ||
          feature.geometry.type === GEOJSON_TYPES.LineString),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        autoClassify: false,
        defaultClass: DEFAULT_LINE_PERIMETER_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        feature.properties.types.includes(DEFAULT_WALL_PERIMETER_TYPE) &&
        (feature.geometry.type === GEOJSON_TYPES.MultiLineString ||
          feature.geometry.type === GEOJSON_TYPES.LineString),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        autoClassify: false,
        defaultClass: DEFAULT_DOORS_LINE_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        feature.properties.types.includes(DOORS_CENTERLINE_ID),
      beforeHook: null,
      afterHook: null,
    },

    {
      data: view?.geojson?.features || [],
      options: {
        ...DEFAULT_OPTIONS,
        defaultClass: DEFAULT_COUNT_ID,
        takeoffType: TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id,
      },
      dataFilter: (feature) =>
        feature?.geometry &&
        feature?.geometry.type === GEOJSON_TYPES.Polygon &&
        feature.properties.types.includes(DEFAULT_COUNT_ID_ML),

      beforeHook: (feature) => {
        const updatedFeature = cleanArrayIndentation(feature);
        updatedFeature.geometry.coordinates = cleanCoordinates(
          updatedFeature.geometry.type,
          updatedFeature.geometry.coordinates
        );

        const centerPt = center({
          type: GEOJSON_TYPES.FeatureCollection,
          features: [updatedFeature],
        });

        const updatedGeometry = isLabellingOrg
          ? updatedFeature.geometry
          : centerPt.geometry;

        const className =
          feature?.properties?.className ||
          feature?.properties?.name.replace(/[0-9]/g, "").trim();

        return {
          ...updatedFeature,
          geometry: updatedGeometry,
          properties: {
            ...feature.properties,
            name: updatedFeature.properties?.name || "",
            className,
            types: updatedFeature.properties?.types || [],
          },
        };
      },
      afterHook: null,
    },
  ].forEach(({ data, options, dataFilter, beforeHook, afterHook }) => {
    const cleanedFeatures = cleanAndPrepareFeatures(
      data,
      { ...options, page: { ...view?.page, bounds } },
      dataFilter,
      beforeHook,
      afterHook
    );
    features.push(...cleanedFeatures);
  });

  const featuresIds = new Set(features.map((fe) => fe.properties.id));
  const manualFeatures = view?.geojson?.features?.filter(
    (f) =>
      !featuresIds.has(f.properties.id) &&
      !f.properties.types.includes(GEOJSON_TYPES.markup) &&
      !f.properties.types.includes(GEOJSON_TYPES.dimensionLine)
  );

  if (manualFeatures?.length > 0) {
    const cleanedFeatures = cleanAndPrepareFeatures(
      manualFeatures,
      { ...DEFAULT_OPTIONS, page: { ...view?.page, bounds } },
      null,
      null,
      null
    );

    features.push(...cleanedFeatures);
  }

  if (
    view?.geojson?.features?.find((f) =>
      f.properties.types.includes(GEOJSON_TYPES.markup)
    )
  ) {
    const markupFeatures = view.geojson.features
      .filter((feature) =>
        feature.properties.types.includes(GEOJSON_TYPES.markup)
      )
      .map((feature) => {
        const featureMeasures =
          getFeatureMeasures(feature, { ...view?.page, bounds }, clMap) ?? {};

        return {
          ...feature,
          properties: {
            ...feature.properties,
            ...featureMeasures,
          },
        };
      });

    features.push(...markupFeatures);
  }

  const ml_features = (features || [])?.filter(
    (fe) => fe?.properties?.ml_classification
  );

  if (ml_features.length > 0) {
    features = features.map((fe) => getSuggestion(fe, ml_features));
  }

  features = cleanPolygonsFeatures(cleanDuplicates(features));

  const url = view.page.url + "?cache=none?dummy=" + new Date().getTime();

  // Calculate the initial zoom with a padding of 0.3 img width
  const defaultZoom = calcZoom(view.page.url_width, view.page.url_height, 0.3);

  view.ml_features = ml_features;

  // Backward compatibility for drawings with no comparison fields
  // will be added here and saved for future use....
  view.comparison_page_id = view.comparison_page_id || "";
  view.comparison_translations = view.comparison_translations || {};

  const classificationsWithDefaults = getClassificationsWIthDefaultFolders(
    classifications,
    features,
    classification_order,
    useMetrics
  );

  return {
    classificationsWithDefaults,
    newView: {
      ...view,
      page: {
        ...view.page,
        imgUrl: url,
        defaultZoom,
        bounds,
        ml_features,
        centerLatLng,
        lastUsedClasses: {
          ALL: [],
          COUNT: [],
          WITH_BOUNDARIES: [],
          JUST_BOUNDARIES: [],
          WITHOUT_BOUNDARIES: [],
        },
      },
      features,
      metadata: {
        ...(view?.metadata || {}),
        multiplier: view?.metadata?.multiplier || 1,
      },
    },
  };
}

// Set utilities

export function cleanCls(cls, useMetrics = false) {
  const defaultQuantity1 =
    cls?.type === CLASSIFICATION_TYPES[0]
      ? QUANTITY_AREA
      : cls?.type === CLASSIFICATION_TYPES[1]
      ? QUANTITY_LENGTH
      : QUANTITY_COUNT;

  const defaultQuantity1UOM = useMetrics
    ? QUANTITIES[defaultQuantity1].defaultMetricUnit.id
    : QUANTITIES[defaultQuantity1].defaultUnit.id;

  return {
    ...cls,
    children: cls?.children?.map((cl) => cleanCls(cl, useMetrics)),
    colorStyle: {
      hex: cls?.colorStyle?.hex || DEFAULT_COLOR.hex,
      fill:
        cls?.colorStyle?.fill?.map((i) => parseInt(i)) || DEFAULT_COLOR.fill,
      color:
        cls?.colorStyle?.color?.map((i) => parseInt(i)) || DEFAULT_COLOR.color,
    },
    visible: true,

    depth: cls?.depth || 0,
    width: cls?.width || 0,
    height: cls?.height || 0,
    length: cls?.length || 0,
    thikness: cls?.thikness || 0,
    verticalSlope: cls?.verticalSlope || 0,
    horizontalSlope: cls?.horizontalSlope || 0,

    folderId: cls?.folderId || null,
    isStatic: cls?.isStatic || false,

    tileGridVer: cls?.tileGridVer || 0,
    tileGridHor: cls?.tileGridHor || 0,
    tileGridGap: cls?.tileGridGap || 0,
    tileGridEnabled: cls?.tileGridVer || false,

    shape: cls?.shape || DEFAULT_SHAPE,
    pattern: cls?.pattern || PATTERNS_DISPLAY[0],
    patternSpacing: cls?.patternSpacing || 1,
    curvedSegment: cls?.curvedSegment || false,
    matchDimentions: cls?.matchDimentions || false,
    displayNameOnHover: cls?.displayNameOnHover || false,
    roundQuantity: Number(cls?.roundQuantity) || DEFAULT_QUANTITY_ROUND,
    displayPatternWhileDrawing: cls?.displayPatternWhileDrawing || false,

    quantity1: cls?.quantity1 || defaultQuantity1,
    quantity2: cls?.quantity2 || null,
    quantity3: cls?.quantity3 || null,
    quantity4: cls?.quantity4 || null,
    quantity1UOM: cls?.quantity1UOM || defaultQuantity1UOM,
    quantity2UOM: cls?.quantity2UOM || null,
    quantity3UOM: cls?.quantity3UOM || null,
    quantity4UOM: cls?.quantity4UOM || null,

    inputGrid: cls?.inputGrid || QUANTITY_PERIMETER,
    inputWidth: cls?.inputWidth || QUANTITY_PERIMETER,
    inputLength: cls?.inputLength || QUANTITY_PERIMETER,
    inputHeight: cls?.inputHeight || QUANTITY_PERIMETER,
    inputOffset: cls?.inputOffset || QUANTITY_PERIMETER,
    inputThickness: cls?.inputThickness || QUANTITY_PERIMETER,

    inputGridUOM: cls?.inputGridUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
    inputHeightUOM:
      cls?.inputHeightUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
    inputWidthUOM:
      cls?.inputWidthUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
    inputLengthUOM:
      cls?.inputLengthUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
    inputThicknessUOM:
      cls?.inputThicknessUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
    inputOffsetUOM:
      cls?.inputOffsetUOM || (useMetrics ? UNIT_METER : UNIT_INCHES),
  };
}

export function prepareClassification(
  classification,
  flatten = true,
  useMetrics = false
) {
  const DEFAULT_CLASSIFICATION = getDefaultClassification(useMetrics);
  classification = [...classification].map((cl) => cleanCls(cl, useMetrics));

  const dfaultIds = new Set();

  if (flatten) {
    classification = flattenClassifications(classification)
      .filter((cls) => cls.category !== CLASSIFICATION_CATEGORIES[1])
      .map((cls) => ({ ...cls, visible: true }));

    const flatDefaultClassifications = new Set(
      flattenClassifications(DEFAULT_CLASSIFICATION).map((cls) => cls.id)
    );

    classification = classification.filter(
      (cls) => !flatDefaultClassifications.has(cls.id)
    );
  }

  const classificationById = getClassificationsByID(classification);

  const DEFAULT_ML_CLS = [
    DEFAULT_ML_AREA_ID,
    DEFAULT_ML_COUNT_ID,
    DEFAULT_ML_WALL_ID,
  ];

  const DEFAULT_CLS = [
    DEFAULT_LINE_ID,
    DEFAULT_AREA_ID,
    DEFAULT_COUNT_ID,
    DEFAULT_REGION_ID,
    DEFAULT_FOOTPRINT_ID,
  ];

  if (!flatten) {
    DEFAULT_CLS.forEach((id) => {
      dfaultIds.add(id);
      const cls_by_id = DEFAULT_CLASSIFICATION.find((cl) => cl.id === id);

      if (cls_by_id.id in classificationById) {
        const current_classification = classificationById[cls_by_id.id];
        classification = updateClassificationById(classification, {
          ...current_classification,
          folderId: current_classification?.folderId || null,
        });
      } else {
        classification = [...classification, cls_by_id];
      }
    });

    DEFAULT_ML_CLS.forEach((ml_id) => {
      let folder = DEFAULT_CLASSIFICATION.find((cl) => cl.id === ml_id);
      if (folder) {
        const folderChildren = folder.children;
        const folderChildrenIds = new Set(folderChildren.map((cls) => cls.id));
        classification = classification.filter(
          (cls) => !folderChildrenIds.has(cls.id)
        );
        classification = [
          ...classification.filter((cls) => cls.id !== ml_id),
          folder,
        ];
      }
    });
  }

  const uniquesIds = new Set(classification.map((cls) => cls.id));

  let cleanedClassifications = [];
  classification.forEach((cls) => {
    if (uniquesIds.has(cls.id)) {
      cleanedClassifications.push(cls);
      uniquesIds.delete(cls.id);
    }
  });

  return cleanedClassifications;
}

export function prepareData(
  vectorData,
  classification,
  index,
  isLabellingOrg,
  classification_order = [],
  useMetrics = false
) {
  let { newView, classificationsWithDefaults } = preparePageData(
    classification,
    vectorData,
    isLabellingOrg,
    classification_order,
    false,
    useMetrics
  );

  // legend cleaning for backward compatibility
  const viewLegend = getViewLegend(newView);
  const filteredFeatures = newView?.features?.filter(
    (fe) =>
      fe?.properties?.className !== GEOJSON_TYPES.legend &&
      !fe?.properties?.types.includes(GEOJSON_TYPES.legend)
  );

  return {
    classificationsWithDefaults,
    newView: {
      ...newView,
      pageIndex: index,
      features: filteredFeatures,
      bounds: newView?.page?.bounds || [],
      centerLatLng: newView?.page?.centerLatLng || [],
      defaultZoom: newView?.page?.defaultZoom || 0,
      imgUrl: newView?.page?.imgUrl || "",
      lastUsedClasses: newView?.page?.lastUsedClasses || null,
      scale_drawing: newView?.page?.scale_drawing || 0,
      scale_real: newView?.page?.scale_real || 0,
      scale_type: newView?.page?.scale_type || "",
      scale_units: newView?.page?.scale_units || "",
      url: newView?.page?.url || "",
      url_dpi: newView?.page?.url_dpi || 0,
      url_height: newView?.page?.url_height || 0,
      url_width: newView?.page?.url_width || 0,
      metadata: {
        ...newView?.metadata,
        legend: viewLegend,
      },
    },
  };
}

export function prepareDataViews(...props) {
  return prepareData(...props).newView;
}

export function getPageImageUrl(page, isComparison = false) {
  if (isComparison) {
    return page?.url || page?.page?.url;
  }

  if (page?.prefix || page?.page?.prefix) {
    return page?.prefix
      ? `${page.prefix}/0/0/0.png`
      : `${page?.page?.prefix}/0/0/0.png`;
  }

  return page?.url || page?.page?.url;
}

function getDefaultActiveView(set, pages, pageId, userId, isLabellingOrg) {
  const page = pages?.find((p) => p?.id === pageId);
  const allPagesViews = pages
    .map((p) => p.views.map((v) => ({ ...v, pageId: p.id })))
    .flat();

  const isUserInSet =
    set?.permissions?.set?.users && userId in set?.permissions?.set?.users;
  const isUserInViews =
    isUserInSet &&
    set.permissions.set.users[userId].name + VIEW_PREFIX in
      set?.permissions?.views;
  const userRole = isUserInSet
    ? set?.permissions?.set?.permissions[userId]
    : null;

  const ownerID = Object.keys(set?.permissions?.set?.permissions).find(
    (k) =>
      set?.permissions?.set?.permissions &&
      k in set?.permissions?.set?.permissions &&
      set.permissions.set.permissions[k] === SET_ROLE_OWNER
  );
  const ownerViewName =
    set?.permissions?.set?.users &&
    ownerID in set?.permissions?.set?.users &&
    set?.permissions?.set?.users[ownerID].name + VIEW_PREFIX;

  const ownerView = allPagesViews.find(
    (v) => v.name === ownerViewName && v.pageId === pageId
  );

  const userView =
    isUserInViews &&
    allPagesViews.find(
      (v) =>
        v.name === set.permissions.set.users[userId].name + VIEW_PREFIX &&
        v.pageId === pageId
    );

  const managerId =
    Object.keys(set?.permissions?.set?.permissions).find(
      (k) => set.permissions.set.permissions[k] === SET_ROLE_OWNER
    ) ||
    Object.keys(set?.permissions?.set?.permissions).find(
      (k) => set.permissions.set.permissions[k] === SET_ROLE_MANAGER
    );

  const managerViewName = set?.permissions?.set?.users?.[managerId]?.name
    ? set.permissions.set.users[managerId].name + VIEW_PREFIX
    : null;
  const managerView = allPagesViews.find(
    (v) => v.name === managerViewName && v.pageId === pageId
  );

  if (Object.keys(set.permissions.views).length === 1) {
    const thisSingleView = allPagesViews.find(
      (v) =>
        v.name === Object.keys(set.permissions.views)[0] && v.pageId === pageId
    );

    return {
      viewName: thisSingleView?.name || Object.keys(set.permissions.views)[0],
      viewId: thisSingleView?.id || null,
    };
  }

  if (isLabellingOrg && managerViewName) {
    return {
      viewName: managerViewName,
      viewId: managerView?.id || null,
    };
  }

  if (isLabellingOrg && ownerViewName) {
    return {
      viewName: ownerViewName,
      viewId: ownerView?.id || null,
    };
  }

  if (isUserInViews && userRole !== SET_ROLE_VIEWER) {
    return {
      viewName:
        userView?.name || set.permissions.set.users[userId].name + VIEW_PREFIX,
      viewId: userView?.id || null,
    };
  }

  if (!isUserInSet || userRole === SET_ROLE_VIEWER) {
    return {
      viewName: COMBINED_VIEW,
      viewId: COMBINED_VIEW,
    };
  }

  if (ownerView) {
    return {
      viewName: ownerView?.name || ownerViewName,
      viewId: ownerView?.id || null,
    };
  }

  if (page) {
    return {
      viewName: COMBINED_VIEW,
      viewId: COMBINED_VIEW,
    };
  }

  return {
    viewName: null,
    viewId: null,
  };
}

export function findViewInPageByUserId(page, userId) {
  const view = page.views.find((v) => v.user_id === userId);
  return view;
}

export async function getView(
  viewName,
  viewId,
  set,
  pages,
  userId,
  pageId,
  orgId,
  classification,
  isLabellingOrg,
  classification_order = [],
  useMetrics
) {
  let page = pages?.find((p) => p.id === pageId);
  if (page?.geojson_text?.features) {
    page = {
      ...page,
      scaled_geojson_text: {
        features: page?.geojson_text?.features || [],
      },
    };
  }

  if (viewName === COMBINED_VIEW) {
    const viewsQuery = await getPageViews(pageId, set.id);
    let views =
      viewsQuery?.rows?.length > 0
        ? viewsQuery.rows
        : [
            {
              created: new Date(),
              features: {
                gross_area: true,
                net_area: true,
                walls_area: true,
                count: true,
                walls_centerline: true,
                footprint: true,
                doors_area: true,
                doors_centerline: true,
                walls_perimeter: true,
              },
              metadata: {},
              id: COMBINED_VIEW + pageId,
              name: COMBINED_VIEW,
              page,
              page_id: page.id,
              user_id: page?.user_id,
            },
          ];
    views = views.map(
      (v, i) =>
        prepareData(
          { ...v, page },
          classification,
          i,
          isLabellingOrg,
          classification_order,
          useMetrics
        ).newView
    );

    const userViewName =
      set?.permissions?.set?.users && userId in set?.permissions?.set?.users
        ? set.permissions.set.users[userId].name + VIEW_PREFIX
        : null;
    const userView = views.find((v) => v.name === userViewName);

    const defaultView = views?.length > 0 ? views[0] : {};

    const combined_view = {
      ...defaultView,
      page: defaultView?.page || page,
      id: COMBINED_VIEW + userView?.id,
      name: COMBINED_VIEW,
      set_id: set.id,
      page_id: pageId,
      organization_id: orgId,
      project_id: set.project_id,
      metadata: {
        ...(userViewName && userView
          ? userView?.metadata || {}
          : views.find((v) => v?.metadata)?.metadata || {}),
      },
      features: views
        .map((v) =>
          (v.features || []).map((fe) => ({
            ...fe,
            properties: {
              ...fe.properties,
              viewId: v.id,
              viewName: v.name,
              id: generateId(),
            },
          }))
        )
        .flat(),
    };

    const classificationsWithDefaults = getClassificationsWIthDefaultFolders(
      classification,
      combined_view?.features || [],
      classification_order || [],
      useMetrics
    );
    return { view: combined_view, classificationsWithDefaults };
  }

  if (viewId) {
    const queryView = await getViewReq(viewId);

    const view = {
      ...queryView,
      set_id: set.id,
      page_id: pageId,
      page,
      organization_id: orgId,
      project_id: set.project_id,
    };

    const { newView: cleaned_view, classificationsWithDefaults } = prepareData(
      view,
      classification,
      0,
      isLabellingOrg,
      classification_order,
      useMetrics
    );

    return { view: cleaned_view, classificationsWithDefaults };
  }

  if (viewName) {
    try {
      const newView = await createView({
        name: viewName,
        set_id: set.id,
        page_id: pageId,
        organization_id: orgId,
        project_id: set.project_id,
      });

      const view = {
        ...newView,
        set_id: set.id,
        page_id: pageId,
        page,
        organization_id: orgId,
        project_id: set.project_id,
      };
      const { newView: cleaned_view, classificationsWithDefaults } =
        prepareData(
          view,
          classification,
          0,
          isLabellingOrg,
          classification_order,
          useMetrics
        );

      return { view: cleaned_view, classificationsWithDefaults };
    } catch (e) {
      const pageViewsQuery = await getSpecifiPageView(
        page.id,
        set.id,
        viewName
      );
      let queryView =
        pageViewsQuery?.rows?.length > 0 ? pageViewsQuery.rows[0] : null;

      if (queryView) {
        const view = {
          ...queryView,
          set_id: set.id,
          page_id: pageId,
          page,
          organization_id: orgId,
          project_id: set.project_id,
        };
        const { newView: cleaned_view, classificationsWithDefaults } =
          prepareData(
            view,
            classification,
            0,
            isLabellingOrg,
            classification_order,
            useMetrics
          );
        return { view: cleaned_view, classificationsWithDefaults };
      }
    }
  }

  if (isLabellingOrg) {
    const allPagesViews = pages
      .map((p) => (p.views || []).map((v) => ({ ...v, pageId: p.id })))
      .flat();

    const ownerID = Object.keys(set?.permissions?.set?.permissions).find(
      (k) =>
        set?.permissions?.set?.permissions &&
        k in set?.permissions?.set?.permissions &&
        set.permissions.set.permissions[k] === SET_ROLE_OWNER
    );
    const ownerViewName =
      set?.permissions?.set?.users &&
      ownerID in set?.permissions?.set?.users &&
      set?.permissions?.set?.users[ownerID].name + VIEW_PREFIX;

    const ownerView = allPagesViews.find(
      (v) => v.name === ownerViewName && v.pageId === pageId
    );

    if (ownerView?.id) {
      const queryView = await getViewReq(ownerView.id);

      const view = {
        ...queryView,
        set_id: set.id,
        page_id: pageId,
        page,
        organization_id: orgId,
        project_id: set.project_id,
      };

      const { newView: cleaned_view, classificationsWithDefaults } =
        prepareData(
          view,
          classification,
          0,
          isLabellingOrg,
          classification_order,
          useMetrics
        );

      return { view: cleaned_view, classificationsWithDefaults };
    }
  }

  return { view: null, classificationsWithDefaults: [] };
}

export function mapTextFeatures(filteredFeatures, deleteIndices) {
  const mapedResults = filteredFeatures?.map((fe, idx) => ({
    ...fe,
    properties: {
      ...fe.properties,
      searchIndex: idx,
      className: deleteIndices?.includes(idx)
        ? DEFAULT_UNASSIGNED_FEATURES_ID
        : fe.properties.className,
      types: [...(fe?.properties?.types || []), DEFAULT_SEARCH_RESULT_ID_TYPES],
    },
  }));
  return mapedResults;
}

export function getPageTextFeatures(page) {
  return (
    page?.scaled_geojson_text?.features ||
    page?.geojson_text?.features ||
    []
  ).filter(Boolean);
}

export function mapPageTextSearchFeatures(page, searchResults, deleteIndices) {
  return mapTextFeatures(
    searchResults?.map((feature) => scaleUpFeature(feature, page)),
    deleteIndices
  );
}

function getOCRClassifications(features) {
  const ocrClassifications = [
    ...features
      .filter((f) => f?.properties?.ocr_classification)
      .map((f) => [...f?.properties?.ocr_classification, f?.geometry?.type])
      .reduce((map, item) => map.set(item[0], item), new Map())
      .values(),
  ].map((f) => {
    const randomIndex = Math.floor(Math.random() * (CLS_COLORS.length - 1));
    const color = CLS_COLORS[randomIndex];

    return {
      category: CLASSIFICATION_CATEGORIES[0],
      type: CLASSIFICATION_TYPES[0],
      label: f[0],
      id: "OCR_" + f[0],
      colorStyle: color,
      children: [],
      shape: DEFAULT_SHAPE,
      visible: false,
      isStatic: true,
      isOCR: true,
      folderId: DEFAULT_FOLDER_OCR_ID,

      quantity1: QUANTITY_AREA,
      quantity2: QUANTITY_AREA,
      quantity3: QUANTITY_AREA,
      quantity4: QUANTITY_AREA,
      quantity1UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
      quantity2UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
      quantity3UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,
      quantity4UOM: QUANTITIES[QUANTITY_AREA].defaultUnit.id,

      width: 0,
      length: 0,
      height: 0,
      offset: 0,
      thikness: 4,

      inputGridUOM: UNIT_FEET,
      inputWidthUOM: UNIT_FEET,
      inputLengthUOM: UNIT_FEET,
      inputHeightUOM: UNIT_FEET,
      inputOffsetUOM: UNIT_FEET,
      inputThicknessUOM: UNIT_FEET,
    };
  });

  if (ocrClassifications?.length > 0) {
    return [DEFAULT_OCR_FOLDER, ...ocrClassifications];
  }
  return [];
}

export function getClassificationsWIthDefaultFolders(
  classifications,
  features = [],
  classification_order = [],
  useMetrics = false
) {
  const filteredFeatures = features.filter(
    (f) =>
      !f?.properties?.types?.includes(GEOJSON_TYPES.markup) &&
      !f?.properties?.types?.includes(GEOJSON_TYPES.dimensionLine)
  );

  let newClassifications = classifications
    .filter(
      (cl) =>
        !cl?.isOCR &&
        !DEFAULT_CLASSIFICATION_IDS_SET.has(cl.id) &&
        !DEFAULT_FOLDERS_IDS_SET.has(cl.id)
    )
    .concat(getOCRClassifications(filteredFeatures));

  const featuresDefaultClassificationsIds = getUniqueArray(
    filteredFeatures.map((fe) => fe?.properties?.className).filter(Boolean)
  ).filter((cls) => DEFAULT_CLASSIFICATION_IDS_SET.has(cls));

  const addedFoldersIds = new Set();
  for (const clsId of featuresDefaultClassificationsIds) {
    const cls =
      clsId in DEFAULT_CLASSIFICATION_MAP && DEFAULT_CLASSIFICATION_MAP[clsId];
    if (cls) {
      newClassifications.push(cls);
      if (cls.folderId && !addedFoldersIds.has(cls.folderId)) {
        const folder =
          cls.folderId in DEFAULT_FOLDERS_MAP &&
          DEFAULT_FOLDERS_MAP[cls.folderId];
        if (folder) {
          newClassifications.push(folder);
          addedFoldersIds.add(cls.folderId);
        }
      }
    }
  }

  const classificationIds = new Set(newClassifications.map((cls) => cls.id));

  if (classification_order?.length > 0) {
    newClassifications = [
      ...classification_order
        .filter((clsId) => classificationIds.has(clsId))
        .map((clsId) => newClassifications.find((cls) => cls.id === clsId)),
      ...newClassifications.filter(
        (cls) => !classification_order.includes(cls.id)
      ),
    ];
  }

  return cleanDefaultClassificationUnits(newClassifications, useMetrics);
}

function getViewLegend(view) {
  if (view?.metadata?.legend) {
    return view.metadata.legend;
  }
  const legendFeature = view?.features?.find(
    (fe) =>
      fe?.properties?.className === GEOJSON_TYPES.legend ||
      fe?.properties?.types?.includes(GEOJSON_TYPES.legend)
  );

  const newLegend = {
    x: legendFeature ? legendFeature?.geometry?.coordinates[0][0][0] : 0,
    y: legendFeature ? legendFeature?.geometry?.coordinates[0][0][1] : 0,
    scale: 4,
    width: 400,
    sort: null,
    showQuantity1: true,
    showQuantity2: false,
    showQuantity3: false,
    showQuantity4: false,
    showCountLabels: true,
    showClassificationCustomId: false,
  };

  return newLegend;
}
