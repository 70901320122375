import rewind from "@turf/rewind";

import {
  Feature,
  Polygon,
  Position,
  Geometry,
  MultiPolygon,
  FeatureCollection,
} from "geojson";
import { generateId } from "../../utils/string";

import ImmutableLayersData, { getFeatureMeasures } from "./ImmutableLayersData";
import { generate3dSceneMesh } from "../3drendering/utils";
import { handleFinishMarkupEdit } from "../../modes/edit/utils";
import { GEOJSON_TYPES, HOLE_TYPE, MODES_IDS } from "../../consts/editor";
import { getPickedEditHandles, getNonGuidePicks } from "../../utils/modes";
import { EditableLayerDraggingEvent } from "./editable-layer";

export type PolygonalFeature = Polygon | MultiPolygon;

export const RECT_SHAPE = "RECT";

interface IGuides {
  features: any[];
  type: string;
}

interface IProps {
  data: any;
  selectedIndexes: number[];
}

const DEFAULT_GUIDES: IGuides = {
  type: GEOJSON_TYPES.FeatureCollection,
  features: [],
};

const DEFAULT_3D_MESH: any = null;

interface ITooltips {
  position: any[];
  text: string;
}

const DEFAULT_TOOLTIPS: ITooltips[] = [];
const DEFAULT_STATE = {
  dataL: 0,
  staticDataL: 0,
};

// from https://github.com/uber/nebula.gl
export default class GeoJsonEditMode {
  _clickSequence: number[][] = [];
  _mesh3d: any = null;
  _isPreparingMesh3d: boolean = false;
  _previousState: any = DEFAULT_STATE;

  _addFeatures: Feature[] = [];
  _editFeatures: Feature[] = [];
  _deleteFeatures: Feature[] = [];

  private _lastFrameTimestamp: number = 0;
  private _animationID: number | null = null;

  private _edgePanPayload: {
    event: EditableLayerDraggingEvent;
    rest: any[];
  } | null = null;
  private _onEdgePanUpdate:
    | ((event: EditableLayerDraggingEvent, props: any, manual: boolean) => void)
    | null = null;

  addFeatures = (features: Feature[]) => {
    this._addFeatures = mergeFeaturesLists(this._addFeatures, features);
    return this;
  };

  editFeatures = (features: Feature[]) => {
    this._editFeatures = mergeFeaturesLists(this._editFeatures, features);
    return this;
  };

  deleteFeatures = (features: Feature[]) => {
    this._deleteFeatures = mergeFeaturesLists(this._deleteFeatures, features);
    this._deleteFeatures.push(...features);
    return this;
  };

  applyActions = (
    props: any,
    shouldSave: boolean = true,
    context: any = {}
  ) => {
    let editFeatures = cleanFeaturesIds(this._editFeatures, shouldSave).map(
      (feature) => {
        const measures =
          getFeatureMeasures(
            feature,
            props.modeConfig.view,
            props.modeConfig.colorMap
          ) ?? {};

        return {
          ...feature,
          properties: {
            ...feature.properties,
            ...measures,
          },
        };
      }
    );

    const addFeatures = cleanFeaturesIds(this._addFeatures, shouldSave);
    const deleteFeatures = cleanFeaturesIds(this._deleteFeatures, shouldSave);

    if (shouldSave) {
      editFeatures = handleFinishMarkupEdit(editFeatures, props);
    }

    props.onEdit({
      addFeatures,
      editFeatures,
      deleteFeatures,
      context: {
        shouldSave,
        ...context,
      },
    });

    if (shouldSave) {
      this._addFeatures = [];
      this._editFeatures = [];
      this._deleteFeatures = [];
    }
  };

  startAnimationLoop() {
    if (!this._animationID) {
      this._lastFrameTimestamp = performance.now();
      this._animationID = window.requestAnimationFrame(
        this._animationLoop.bind(this)
      );
    }
  }

  stopAnimationLoop() {
    if (this._animationID) {
      window.cancelAnimationFrame(this._animationID);
      this._animationID = null;
    }
  }

  generateMesh = async (
    data: any,
    colorMap: any,
    referenceInchesPerPixels = 0
  ) => {
    this._isPreparingMesh3d = true;

    if (!referenceInchesPerPixels) {
      this._isPreparingMesh3d = false;
      return;
    }

    const sceneMesh = await generate3dSceneMesh(
      data,
      colorMap,
      referenceInchesPerPixels
    );
    if (sceneMesh) {
      this._mesh3d = sceneMesh;
    } else {
      this._mesh3d = null;
    }

    this._isPreparingMesh3d = false;
  };

  private _animationLoop(timestamp: number) {
    const timeElapsed = timestamp - this._lastFrameTimestamp;

    this._animationID = window.requestAnimationFrame(
      this._animationLoop.bind(this)
    );

    if (timeElapsed >= 1000 / 60) {
      this._animate();
      this.animate();
      this._lastFrameTimestamp = timestamp;
    }
  }

  private _animate() {
    if (this._edgePanPayload && this._onEdgePanUpdate) {
      this._edgePanPayload.event.passive = true;
      this._onEdgePanUpdate(
        this._edgePanPayload.event,
        // @ts-ignore
        ...this._edgePanPayload.rest
      );
    }
  }

  animate() {
    return;
  }

  getGuides(props: any) {
    return DEFAULT_GUIDES;
  }

  getStaticGuides(props: any) {
    return DEFAULT_GUIDES;
  }

  get3dGuides(props: any) {
    return DEFAULT_3D_MESH;
  }

  getTooltips(props: any) {
    return DEFAULT_TOOLTIPS;
  }

  getSelectedFeature(props: IProps): Feature<Geometry> {
    if (props.selectedIndexes.length === 1) {
      return props.data.features[props.selectedIndexes[0]];
    }
    return null;
  }

  getSelectedFeatureWithHoles(props: any): Feature<Geometry> {
    if (props.selectedIndexes.length === 0) return null;

    for (const indice of props.selectedIndexes) {
      const feature = props.data.features[indice];
      if (!feature.properties.className.includes(HOLE_TYPE)) {
        return feature;
      }
    }
  }

  getSelectedGeometry = (props: any): Feature<Geometry> => {
    const feature: Feature<Geometry> = this.getSelectedFeatureWithHoles(props);
    if (feature) {
      return feature.geometry as unknown as Feature<Geometry>;
    }
    return null;
  };

  getSelectedFeaturesAsFeatureCollection(props: any): FeatureCollection {
    const { features } = props.data;
    const selectedFeatures = props.selectedIndexes.map(
      (selectedIndex: number) => features[selectedIndex]
    );
    return {
      type: GEOJSON_TYPES.FeatureCollection,
      features: selectedFeatures,
    };
  }

  getClickSequence = () => {
    return this._clickSequence;
  };

  setClickSequence = (coords: any) => {
    this._clickSequence = coords;
  };

  addClickSequence = ({ mapCoords }: any) => {
    this._clickSequence.push(mapCoords);
  };

  resetClickSequence = () => {
    this._clickSequence = [];
  };

  getTentativeGuide = (props: any, guideType: string = "tentative") => {
    const guides = this.getGuides(props);

    return guides.features.find(
      (f) => f.properties && f.properties.guideType === guideType
    );
  };

  isSelectionPicked(picks: any[], props: any) {
    if (!picks.length) return false;
    const pickedFeatures = getNonGuidePicks(picks).map(({ index }) => index);
    const pickedHandles = getPickedEditHandles(picks).map(
      ({ properties }) => properties.featureIndex
    );
    const pickedIndexes = new Set([...pickedFeatures, ...pickedHandles]);
    return props.selectedIndexes.some((index: number) =>
      pickedIndexes.has(index)
    );
  }

  rewindPolygon(feature: Feature<Geometry>): Feature<Geometry> {
    const { geometry } = feature;

    const isPolygonal =
      geometry.type === GEOJSON_TYPES.Polygon ||
      geometry.type === GEOJSON_TYPES.MultiPolygon;
    if (isPolygonal) {
      return rewind(feature as Feature<PolygonalFeature>);
    }

    return feature;
  }

  getAddFeatureAction = (
    featureOrGeometry: Feature | Geometry,
    view: any,
    types: any[],
    className: string = null,
    modeId?: string | null,
    featureAccessKey?: string,
    colorMap?: any,
    arcs?: any
  ): Feature[] | null => {
    const feature =
      featureOrGeometry.type === GEOJSON_TYPES.Feature
        ? featureOrGeometry
        : {
            type: GEOJSON_TYPES.Feature,
            properties: {},
            geometry: featureOrGeometry,
          };

    if (!modeId) {
      return [feature];
    }

    // const rewindFeature = this.rewindPolygon(feature);

    const updatedDataId = generateId();
    const updatedData = new ImmutableLayersData([])
      .addFeature(
        feature.geometry,
        null,
        updatedDataId,
        className,
        types.length === 1 ? types[0] : null,
        view,
        modeId === MODES_IDS.rect ? [RECT_SHAPE] : [],
        null,
        featureAccessKey,
        colorMap,
        {
          arcs: arcs || [],
        }
      )
      .getLayers();

    return updatedData;
  };

  getAddFeatureOrBooleanPolygonAction = (
    featureOrGeometry: Feature | Geometry,
    props: any,
    className: string = null,
    arcs?: any
  ) => {
    const { view, filteredtakeOffTypes, modeId, colorMap } = props.modeConfig;

    return this.getAddFeatureAction(
      featureOrGeometry,
      view,
      filteredtakeOffTypes,
      className,
      modeId,
      props.featureAccessKey,
      colorMap,
      arcs
    );
  };

  _enableEdgePanning(
    onPanUpdate: (
      event: EditableLayerDraggingEvent,
      props: any,
      manual: boolean
    ) => void
  ) {
    this._onEdgePanUpdate = onPanUpdate;
    this.startAnimationLoop();
  }

  _updateEdgePanning(event: EditableLayerDraggingEvent, ...rest: any[]) {
    this._edgePanPayload = {
      event,
      rest,
    };
  }

  _disableEdgePanning() {
    this._edgePanPayload = null;
    this._onEdgePanUpdate = null;
    this.stopAnimationLoop();
  }
}

export function getIntermediatePosition(
  position1: Position,
  position2: Position
): Position {
  return [
    (position1[0] + position2[0]) / 2.0,
    (position1[1] + position2[1]) / 2.0,
  ];
}

export function mergeFeaturesLists(
  features: any[],
  newFeatures: any[],
  addFeatures: boolean = true
) {
  const cleanedFeatures = features;

  for (const feature of newFeatures) {
    const featureIndex = cleanedFeatures.findIndex(
      (f) => f?.properties?.id === feature?.properties?.id
    );

    if (featureIndex > -1) {
      cleanedFeatures[featureIndex] = feature;
    } else if (addFeatures) {
      cleanedFeatures.push(feature);
    }
  }

  return cleanedFeatures;
}

function cleanFeaturesIds(features: any[], shouldSave = true) {
  return features.map((feature) => {
    return {
      ...feature,
      properties: {
        ...feature?.properties,
        id:
          shouldSave && feature?.properties?.id?.includes("copied")
            ? generateId()
            : feature?.properties?.id,
      },
    };
  });
}
