import { Feature, LineString, MultiPoint, Position } from "geojson";

import {
  TAKE_OFFS,
  MANUAL_DATA,
  GEOJSON_TYPES,
  TAKE_OFF_TYPES,
  DEFAULT_LINE_ID,
  DEFAULT_AREA_ID,
  DEFAULT_COUNT_ID,
  DEFAULT_REGION_ID,
} from "../../consts/editor";
import {
  UNITS,
  QUANTITY1,
  QUANTITY2,
  QUANTITY3,
  QUANTITY4,
  QUANTITIES,
  FORMULA_SUFFIX,
  QUANTITIES_UOM,
  QUANTITY_CUTSOM,
} from "../../consts/classifications";
import { SHAPES } from "../../consts/svg";
import { INCHES_TO_FEET } from "../../consts/geometry";
import { MODE_SPHERE, MODE_RECTANGLE } from "../../consts/coordinates";

import {
  getCoords,
  getPerimeterInches,
  getAreaSquareInches,
  pruneGeometryIfNecessary,
} from "../../utils/coordinates";
import { getLocale } from "../../i18n";
import { generateId } from "../../utils/string";
import { getSuggestion } from "../../utils/data";
import { getValueByKey } from "../../utils/modes";
import { getUniqueArray } from "../../utils/array";
import { FeatureTypesWithCoordinates } from "../types";
import { distinct, tail } from "../../utils/functional/list";
import { getFeatureUnit, getFeaturesUnitSum } from "../../utils/metrics";

import {
  FORUMULA_FUNCTIONS,
  FORMULA_EXPRESSIONS,
  FORMUL_FUNCTIONS_MAP,
} from "../../components/v2/FormulaInput/const";
import { getValidFormula } from "../../components/v2/FormulaInput/utils";

interface IFilter {
  key: string;
  value: string;
  type: number;
  func?: Function;
}

export interface IFeatureMeasures {
  area: number;
  length: number;
  volume: number;
  perimeter: number;
  perimeter_surface_area: number;
  area_slope: number;
  length_slope: number;
  tile_count: number;
  hor_area: number;
  ver_area_1_side: number;
  ver_area_2_sides: number;
  ver_area_all_sides: number;
  count: number;
  total_height: number;
  slope_factor: number;
}

export function makeMarkupFeature({
  feature,
  name,
  type,
  id,
  view,
  clMap,
}: {
  feature: any;
  name?: string;
  type?: string;
  id?: string;
  view?: any;
  clMap?: any;
}) {
  const types = getUniqueArray([
    feature?.properties?.modeId,
    ...(feature?.properties?.types || []),
    MANUAL_DATA,
    TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id,
    TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id,
    TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
    TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id,
    GEOJSON_TYPES.markup,
    type,
  ]).filter(Boolean);

  const measures =
    (view && clMap ? getFeatureMeasures(feature, view, clMap) : {}) ?? {};

  return {
    ...feature,
    properties: {
      ...feature.properties,
      types,
      height: 0,
      name: name || "",
      className: GEOJSON_TYPES.markupGroup,
      id: feature?.properties?.id || id || generateId(),
      ...measures,
    },
  };
}

export function makeFeature({
  geometry,
  id,
  name,
  view,
  clMap,
  type,
  className,
  additionTypes = [],
  featureAccessKey = null,
  defaultPerimeter = null,
  featureProperties = {},
}: any) {
  const holesArray = tail(geometry?.coordinates);

  const defaultClassName =
    geometry.type === GEOJSON_TYPES.Point
      ? DEFAULT_COUNT_ID
      : geometry.type === GEOJSON_TYPES.LineString ||
        geometry.type === GEOJSON_TYPES.MultiLineString
      ? DEFAULT_LINE_ID
      : DEFAULT_REGION_ID;

  const defaultType =
    geometry.type === GEOJSON_TYPES.Point
      ? TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id
      : geometry.type === GEOJSON_TYPES.LineString ||
        geometry.type === GEOJSON_TYPES.MultiLineString
      ? TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id
      : TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id;

  let cleanedCoords =
    geometry.type === GEOJSON_TYPES.Polygon ? [] : geometry.coordinates;
  geometry.type === GEOJSON_TYPES.Polygon
    ? [...new Set(geometry.coordinates[0])]
    : geometry.coordinates;

  let prev =
    geometry.type === GEOJSON_TYPES.Polygon && geometry.coordinates[0][0];

  if (geometry.type === GEOJSON_TYPES.Polygon) {
    for (const coord of geometry.coordinates[0]) {
      if (coord[0] === prev[0] && coord[1] === prev[1]) {
      } else {
        cleanedCoords.push(coord);
      }
      prev = coord;
    }
    cleanedCoords = [[geometry.coordinates[0][0], ...cleanedCoords]];

    holesArray.forEach((item: any, index: number) => {
      let cleanedCoordsHoles =
        geometry.type === GEOJSON_TYPES.Polygon ? [] : item;
      geometry.type === GEOJSON_TYPES.Polygon ? distinct(item[index]) : item;

      let prevHoles = item[0];

      for (const coord of item) {
        if (!(coord[0] === prevHoles[0] && coord[1] === prevHoles[1])) {
          cleanedCoordsHoles.push(coord);
        }
        prevHoles = coord;
      }
      cleanedCoords = [...cleanedCoords, [prevHoles, ...cleanedCoordsHoles]];
    });
  }

  let newFeature: any = {
    type: "Feature",
    geometry: {
      ...geometry,
      coordinates: cleanedCoords,
    },
    properties: {
      name: name || "",
      perimeter: defaultPerimeter || 0,
      className: featureAccessKey
        ? defaultClassName
        : className || defaultClassName,
      id: id || generateId(),
      types: [
        type || defaultType,
        ...additionTypes,
        featureAccessKey,
        MANUAL_DATA,
      ].filter(Boolean),
      suggestion: null,
      ...(featureAccessKey
        ? { auxiliaryClassNames: { [featureAccessKey]: className } }
        : {}),
      ...(featureProperties || {}),
    },
  };

  const { perimeter, ...measures } = getFeatureMeasures(
    newFeature,
    view,
    clMap
  );

  newFeature = {
    ...newFeature,
    properties: {
      ...newFeature.properties,
      ...measures,
      perimeter: defaultPerimeter || perimeter,
    },
  };

  return getSuggestion(newFeature, view.ml_features);
}

export function updateFeatureArcs(
  features: any,
  id: string,
  originalFeature: any,
  view: any,
  clMap?: any
) {
  return features.map((feature: any) => {
    if (!feature?.geometry) {
      return feature;
    }

    if (feature.properties.id === id) {
      const arcs: any = [];

      const originalArcs = originalFeature?.properties?.arcs || [];

      if (
        originalFeature?.geometry?.type === GEOJSON_TYPES.Polygon &&
        feature.geometry.type === GEOJSON_TYPES.Polygon &&
        originalArcs.length > 0
      ) {
        const coordinates = originalFeature.geometry.coordinates[0];
        const featureCoordinates = feature.geometry.coordinates[0];

        originalArcs.forEach((arc: any) => {
          const indices = [
            arc.startPointIdx,
            arc.endPointIdx,
            ...arc.ignoreIndices,
          ];
          const arcCoordinates = coordinates.filter((c: any, idx: number) =>
            indices.includes(idx)
          );

          const matchingIndices: any = [];
          arcCoordinates.forEach((coord: any) => {
            featureCoordinates.forEach((fc: any, jdx: number) => {
              if (
                Math.round(fc[0]) == Math.round(coord[0]) &&
                Math.round(fc[1]) == Math.round(coord[1])
              ) {
                matchingIndices.push(jdx);
              }
            });
          });

          if (matchingIndices.length === indices.length) {
            const startIndex = Math.min(...matchingIndices);
            const endIndex = Math.max(...matchingIndices);
            const ignoreIndices = new Array(endIndex - startIndex - 1)
              .fill(0)
              .map((_, idx) => idx + startIndex + 1);

            if (ignoreIndices.every((ij) => matchingIndices.includes(ij))) {
              const matchedArc = {
                ...arc,
                startPointIdx: startIndex,
                endPointIdx: endIndex,
                ignoreIndices: ignoreIndices,
              };

              arcs.push(matchedArc);
            }
          }
        });
      }

      const measures = getFeatureMeasures(feature, view, clMap);

      return {
        ...feature,
        properties: {
          ...feature.properties,
          ...measures,
          arcs,
        },
      };
    } else {
      return feature;
    }
  });
}

export default class ImmutableLayersData {
  private features: Feature<FeatureTypesWithCoordinates>[];

  constructor(features: any[]) {
    this.features = features || [];
  }

  getLayers = (): Feature<FeatureTypesWithCoordinates>[] => {
    return this.features;
  };

  applyFilters = (filters: Record<string, IFilter>, groupMap: any) => {
    let tempFeatures = this.features;

    for (const key of Object.keys(filters)) {
      const filter = filters[key];
      if (filter.value) {
        tempFeatures = tempFeatures?.filter((fe) => {
          switch (filter.type) {
            case 0:
              return getValueByKey(fe, filter.key) === filter.value;
            case 1:
              return getValueByKey(fe, filter.key) > filter.value;
            case 2:
              return getValueByKey(fe, filter.key) < filter.value;
            case 3:
              return getValueByKey(fe, filter.key)?.includes(filter.value);
            case 4:
              return getValueByKey(fe, filter.key)?.some((i: string) =>
                filter.value.includes(i)
              );
            case 5:
              return filter.func(
                filter.value,
                getValueByKey(fe, filter.key),
                groupMap
              );
            default:
              return getValueByKey(fe, filter.key) === filter.value;
          }
        });
      }
    }

    this.features = tempFeatures;
    return this;
  };

  filterGeometryType = (types: any[], out: boolean = false) => {
    this.features = this.features.filter((fe) =>
      out ? !types.includes(fe.geometry.type) : types.includes(fe.geometry.type)
    );
    return this;
  };

  filterSelected = (selections: any[], out: boolean = false) => {
    this.features = this.features.filter((fe) =>
      out
        ? !selections.includes(fe.properties.id)
        : selections.includes(fe.properties.id)
    );
    return this;
  };

  merge = (newFeatures: any[]) => {
    this.features = [...newFeatures, ...this.features];

    return this;
  };

  convertPolygonsToLines = (properties: any) => {
    let newLines: any[] = [];

    this.features.forEach((fe: Feature<FeatureTypesWithCoordinates>) => {
      if (
        fe.geometry.type === GEOJSON_TYPES.Polygon &&
        fe.geometry.coordinates.length >= 1
      ) {
        // remove last coordinate
        fe.geometry.coordinates.forEach((polygonCoords: number[][]) => {
          if (polygonCoords?.length > 3) {
            const polygonCoordsWithoutLast = polygonCoords.slice(0, -1);
            polygonCoordsWithoutLast.forEach(
              (lineCoords: number[], idx: number) => {
                if (lineCoords?.length === 2) {
                  const nextPoint =
                    polygonCoordsWithoutLast[
                      idx === polygonCoordsWithoutLast?.length - 1 ? 0 : idx + 1
                    ];

                  let newLine = {
                    type: GEOJSON_TYPES.Feature,
                    geometry: {
                      type: GEOJSON_TYPES.LineString,
                      coordinates: [lineCoords, nextPoint],
                    },
                    properties: {
                      ...properties,
                      id: generateId(),
                    },
                  };

                  newLines = [...newLines, newLine];
                }
              }
            );
          }
        });
      }
    });

    this.features = newLines;
    return this;
  };

  updateMeasures = (ids: string[], view: any, clMap?: any) => {
    const idsSet = new Set(ids);

    this.features = this.features.map((feature) => {
      if (!idsSet.has(feature.properties.id) || !feature.geometry) {
        return feature;
      }

      const measures = getFeatureMeasures(feature, view, clMap);

      return {
        ...feature,
        properties: {
          ...feature.properties,
          ...measures,
        },
      };
    });

    return this;
  };

  makeIntoLines = (view: any, clMap?: any) => {
    let newLines: any[] = [];

    this.features.forEach((fe: Feature<LineString>) => {
      fe.geometry.coordinates.forEach((line: number[]) => {
        let lineFeature = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: line,
          },
          properties: {
            ...this.features[0].properties,
            id: generateId(),
          },
        };

        const measures = getFeatureMeasures(lineFeature, view, clMap);

        lineFeature = {
          ...lineFeature,
          properties: {
            ...lineFeature.properties,
            ...measures,
          },
        };
        newLines = [...newLines, lineFeature];
      });
    });

    this.features = newLines;
    return this;
  };

  makeIntoMultiLine = (view: any, clMap?: any) => {
    let coords: any = [];

    this.features.forEach((fe: Feature<FeatureTypesWithCoordinates>) => {
      if (fe.geometry.type === GEOJSON_TYPES.MultiLineString) {
        fe.geometry.coordinates.forEach((line: any) => {
          coords = [...coords, line];
        });
      } else {
        coords = [...coords, fe.geometry.coordinates];
      }
    });

    let newLayer = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.MultiLineString,
        coordinates: coords,
      },
      properties: {
        ...this.features[0].properties,
        id: generateId(),
      },
    };

    const measures = getFeatureMeasures(newLayer, view, clMap);
    newLayer = {
      ...newLayer,
      properties: {
        ...newLayer.properties,
        ...measures,
      },
    };

    this.features = [newLayer];
    return this;
  };

  makeIntoPolygons = (view: any, clMap?: any) => {
    let newPolygons: any[] = [];

    this.features.forEach((feature) => {
      feature.geometry.coordinates.forEach((polygon: any) => {
        let newPolygon = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.Polygon,
            coordinates: polygon,
          },
          properties: {
            ...this.features[0].properties,
            id: generateId(),
          },
        };

        const measures = getFeatureMeasures(newPolygon, view, clMap);
        newPolygon = {
          ...newPolygon,
          properties: {
            ...newPolygon.properties,
            ...measures,
          },
        };
        newPolygons = [...newPolygons, newPolygon];
      });
    });

    this.features = newPolygons;
    return this;
  };

  makeIntoMultiPolygons = (view: any, clMap?: any) => {
    let coords: any[] = [];

    this.features.forEach((feature: Feature<FeatureTypesWithCoordinates>) => {
      if (feature.geometry.type === GEOJSON_TYPES.MultiPolygon) {
        feature.geometry.coordinates.forEach((polygon: any) => {
          coords = [...coords, polygon];
        });
      } else {
        coords = [...coords, feature.geometry.coordinates];
      }
    });

    let newLayer = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.MultiPolygon,
        coordinates: coords,
      },
      properties: {
        ...this.features[0].properties,
        id: generateId(),
      },
    };

    const measures = getFeatureMeasures(newLayer, view, clMap);
    newLayer = {
      ...newLayer,
      properties: {
        ...newLayer.properties,
        ...measures,
      },
    };

    this.features = [newLayer];
    return this;
  };

  makeIntoPoints = (view?: any, clMap?: any) => {
    let newPoints: any[] = [];

    this.features.forEach((feature: Feature<MultiPoint>) => {
      feature.geometry.coordinates.forEach((point: number[]) => {
        let newPoint = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.Point,
            coordinates: point,
          },
          properties: {
            ...this.features[0].properties,
            id: generateId(),
            perimeter: 0,
            area: 0,
          },
        };

        const measures = getFeatureMeasures(newPoint, view, clMap);
        newPoint = {
          ...newPoint,
          properties: {
            ...newPoint.properties,
            ...measures,
          },
        };

        newPoints = [...newPoints, newPoint];
      });
    });

    this.features = newPoints;
    return this;
  };

  makeIntoMultiPoints = (view?: any, clMap?: any) => {
    let coords: any[] = [];

    this.features.forEach((fe) => {
      if (fe.geometry.type === GEOJSON_TYPES.MultiPoint) {
        fe.geometry.coordinates.forEach((point: number[]) => {
          coords = [...coords, point];
        });
      } else {
        coords = [...coords, fe.geometry.coordinates];
      }
    });

    let newLayer = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.MultiPoint,
        coordinates: coords,
      },
      properties: {
        ...this.features[0].properties,
        id: generateId(),
        perimeter: 0,
        area: 0,
      },
    };

    const measures = getFeatureMeasures(newLayer, view, clMap);
    newLayer = {
      ...newLayer,
      properties: {
        ...newLayer.properties,
        ...measures,
      },
    };

    this.features = [newLayer];
    return this;
  };

  addFeature = (
    geometry: any,
    name: string,
    id: string,
    className: string,
    type: string,
    view: any,
    additionTypes: any[] = [],
    defaultPerimeter: any = null,
    featureAccessKey: string = null,
    clMap: any = null,
    featureProperties: any = {}
  ) => {
    const newFeature = makeFeature({
      geometry,
      id,
      name,
      view,
      clMap,
      type,
      className,
      additionTypes,
      featureAccessKey,
      defaultPerimeter,
      featureProperties,
    });

    this.features = [newFeature, ...this.features];
    return this;
  };

  addFeatures = (
    features: Feature<FeatureTypesWithCoordinates>[],
    view: any,
    clMap?: any
  ) => {
    let newFeatures: any[] = [];

    features.forEach((feature) => {
      if (!feature.geometry) {
        return;
      }

      const defaultClassName =
        {
          [GEOJSON_TYPES.Point]: DEFAULT_COUNT_ID,
          [GEOJSON_TYPES.LineString]: DEFAULT_LINE_ID,
          [GEOJSON_TYPES.MultiLineString]: DEFAULT_LINE_ID,
        }[feature.geometry.type as string] ?? DEFAULT_AREA_ID;
      const defaultType =
        {
          [GEOJSON_TYPES.Point]: TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id,
          [GEOJSON_TYPES.LineString]:
            TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
          [GEOJSON_TYPES.MultiLineString]:
            TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id,
        }[feature.geometry.type as string] ??
        TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id;

      let newFeature = {
        type: "Feature",
        geometry: feature.geometry,
        properties: {
          name: feature.properties?.name || "",
          className: feature.properties?.className || defaultClassName,
          id: feature.properties?.id || generateId(),
          types: feature.properties?.types || [defaultType],
        },
      };

      const measures = getFeatureMeasures(newFeature, view, clMap);
      newFeature = {
        ...newFeature,
        properties: {
          ...newFeature.properties,
          ...measures,
        },
      };

      newFeatures = [...newFeatures, newFeature];
    });

    this.features = [...this.features, ...newFeatures];
    return this;
  };

  addMarkupFeature = (
    feature: any,
    name: string,
    type: string,
    id?: string,
    view?: any,
    clMap?: any
  ): ImmutableLayersData => {
    let newFeature = makeMarkupFeature({
      feature,
      name,
      type,
      id,
      view,
      clMap,
    });

    this.features = [newFeature, ...this.features];
    return this;
  };

  replaceGeometry = (
    id: string,
    geometry: any,
    view: any,
    clMap?: any
  ): ImmutableLayersData => {
    if (!geometry) {
      return this;
    }

    this.features = this.features.map((feature) => {
      if (feature.properties.id === id) {
        let newFeature = {
          ...feature,
          geometry,
        };

        const measures = getFeatureMeasures(newFeature, view, clMap);
        newFeature = {
          ...newFeature,
          properties: {
            ...newFeature.properties,
            ...measures,
          },
        };

        return newFeature;
      } else {
        return feature;
      }
    });

    return this;
  };

  fixArcs = (id: string, originalFeature: any, view: any, clMap?: any) => {
    updateFeatureArcs(this.features, id, originalFeature, view, clMap);
    return this;
  };

  replacePosition = (
    id: string,
    positionIndexes: number[],
    updatedPosition: number[],
    view: any,
    isAdd: boolean = false,
    clMap?: any
  ) => {
    this.features = this.features.map(
      (feature: Feature<FeatureTypesWithCoordinates>) => {
        if (!feature.geometry) {
          return feature;
        }

        if (feature.properties.id === id) {
          const isPolygonal =
            feature.geometry.type === GEOJSON_TYPES.Polygon ||
            feature.geometry.type === GEOJSON_TYPES.MultiPolygon;
          const updatedGeometry = {
            ...feature.geometry,
            coordinates: this.immutablyReplacePosition(
              feature.geometry.coordinates as number[] | number[][],
              positionIndexes,
              updatedPosition,
              isPolygonal,
              isAdd
            ),
          };

          let newFeature = {
            ...feature,
            geometry: updatedGeometry,
          };

          const measures = getFeatureMeasures(newFeature, view, clMap);
          newFeature = {
            ...newFeature,
            properties: {
              ...newFeature.properties,
              ...measures,
            },
          };

          return newFeature;
        }

        return feature;
      }
    );

    return this;
  };

  removeArc = (
    id: string,
    positionIndexes: number[],
    view: any,
    clMap?: any,
    shouldRemoveArc = true
  ) => {
    this.features = this.features.map((feature) => {
      if (!feature.geometry) {
        return feature;
      }

      const isLineStringOrPolygon = (
        [GEOJSON_TYPES.LineString, GEOJSON_TYPES.Polygon] as string[]
      ).includes(feature.geometry.type);

      if (feature.properties.id === id && isLineStringOrPolygon) {
        let newFeature: any = feature;
        let coordinates =
          feature.geometry.type === GEOJSON_TYPES.LineString
            ? feature.geometry.coordinates
            : (feature.geometry.coordinates[0] as Position[]);
        let arcs = feature.properties?.arcs || [];

        const arcIndex = positionIndexes[positionIndexes.length - 1];
        const arcsToRemove = arcs
          .filter((a: any) =>
            [a.startPointIdx, a.endPointIdx, ...a.ignoreIndices].includes(
              arcIndex
            )
          )
          .sort((a1: any, a2: any) =>
            a1.startPointIdx < a2.startPointIdx ? 1 : -1
          );

        const arcsToRemoveIds = arcsToRemove.map((a: any) => a.id);

        if (
          shouldRemoveArc &&
          ![MODE_SPHERE, MODE_RECTANGLE].includes(arcsToRemove[0].type)
        ) {
          arcsToRemove.forEach((arc: any) => {
            const controlIndex =
              arc?.ignoreIndices[Math.ceil((arc.ignoreIndices.length - 1) / 2)];
            const indicesToRemove = arc?.ignoreIndices.filter(
              (i: any) => i !== controlIndex
            );

            coordinates = coordinates.filter(
              (_, idx: number) => !indicesToRemove.includes(idx)
            );

            newFeature = {
              ...newFeature,
              geometry: {
                ...newFeature.geometry,
                coordinates:
                  newFeature.geometry?.type === GEOJSON_TYPES.LineString
                    ? coordinates
                    : [coordinates],
              },
            };
            const padding = indicesToRemove.length;

            arcs = arcs.map((a: any) =>
              a.startPointIdx >= arc.startPointIdx
                ? {
                    ...a,
                    startPointIdx: a.startPointIdx - padding,
                    endPointIdx: a.endPointIdx - padding,
                    ignoreIndices: a.ignoreIndices.map((i: any) => i - padding),
                  }
                : a
            );
          });
        }

        arcs = arcs.filter((a: any) => !arcsToRemoveIds.includes(a.id));

        const measures = getFeatureMeasures(newFeature, view, clMap);
        newFeature = {
          ...newFeature,
          properties: {
            ...newFeature.properties,
            ...measures,
            arcs,
          },
        };

        return newFeature;
      }

      return feature;
    });

    return this;
  };

  removePosition = (
    id: string,
    positionIndexes: number[],
    view: any,
    clMap?: any
  ) => {
    this.features = this.features.map((feature) => {
      if (!feature.geometry) {
        return feature;
      }

      if (feature.properties.id === id) {
        if (feature.geometry.type === GEOJSON_TYPES.Point) {
          return feature;
        }

        if (
          feature.geometry.type === GEOJSON_TYPES.LineString &&
          feature.geometry.coordinates.length < 3
        ) {
          return feature;
        }

        if (
          feature.geometry.type === GEOJSON_TYPES.Polygon && // outer ring is a triangle
          feature.geometry.coordinates[0].length < 5 &&
          Array.isArray(positionIndexes) && // trying to remove from outer ring
          positionIndexes[0] === 0
        ) {
          return feature;
        }

        if (
          feature.geometry.type === GEOJSON_TYPES.MultiLineString && // only 1 LineString left
          feature.geometry.coordinates.length === 1 && // only 2 positions
          feature.geometry.coordinates[0].length < 3
        ) {
          return feature;
        }

        if (
          feature.geometry.type === GEOJSON_TYPES.MultiPolygon && // only 1 polygon left
          feature.geometry.coordinates.length === 1 && // outer ring is a triangle
          feature.geometry.coordinates[0][0].length < 5 &&
          Array.isArray(positionIndexes) && // trying to remove from first polygon
          positionIndexes[0] === 0 && // trying to remove from outer ring
          positionIndexes[1] === 0
        ) {
          return feature;
        }

        const isPolygonal = (
          [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon] as string[]
        ).includes(feature.geometry.type);

        const updatedGeometry = {
          ...feature.geometry,
          coordinates: this.immutablyRemovePosition(
            feature.geometry.coordinates as number[] | number[][],
            positionIndexes,
            isPolygonal
          ),
        };

        const arcIndex = positionIndexes[positionIndexes.length - 1];
        const arcsToRemoveIds = (feature.properties?.arcs || [])
          .filter((a: any) =>
            [a.startPointIdx, a.endPointIdx, ...a.ignoreIndices].includes(
              arcIndex
            )
          )
          .map((a: any) => a.id);

        pruneGeometryIfNecessary(updatedGeometry);

        let newFeature = {
          ...feature,
          geometry: updatedGeometry,
        };

        const measures = getFeatureMeasures(newFeature, view, clMap);
        newFeature = {
          ...newFeature,
          properties: {
            ...newFeature.properties,
            ...measures,
            arcs: (feature.properties?.arcs || [])
              .filter((a: any) => !arcsToRemoveIds.includes(a.id))
              .map((a: any) =>
                a.startPointIdx >= arcIndex
                  ? {
                      ...a,
                      startPointIdx: a.startPointIdx - 1,
                      endPointIdx: a.endPointIdx - 1,
                      ignoreIndices: a.ignoreIndices.map((i: any) => i - 1),
                    }
                  : a
              ),
          },
        };

        return newFeature;
      }

      return feature;
    });

    return this;
  };

  addPosition = (
    id: string,
    positionIndexes: number[],
    positionToAdd: number[],
    view: any,
    clMap?: any
  ) => {
    this.features = this.features.map((feature) => {
      if (!feature.geometry) {
        return feature;
      }

      if (feature.properties.id === id) {
        if (feature.geometry.type === GEOJSON_TYPES.Point) {
          return feature;
        }

        const isPolygonal = (
          [GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon] as string[]
        ).includes(feature.geometry.type);

        const arcIndex = positionIndexes[positionIndexes.length - 1];

        const updatedGeometry = {
          ...feature.geometry,
          coordinates: this.immutablyAddPosition(
            feature.geometry.coordinates as number[] | number[][],
            positionIndexes,
            positionToAdd,
            isPolygonal
          ),
        };

        let newFeature = {
          ...feature,
          geometry: updatedGeometry,
        };

        const measures = getFeatureMeasures(newFeature, view, clMap) ?? {};

        newFeature = {
          ...newFeature,
          properties: {
            ...newFeature.properties,
            ...measures,
            arcs: (feature.properties?.arcs || []).map((a: any) =>
              a.startPointIdx >= arcIndex
                ? {
                    ...a,
                    startPointIdx: a.startPointIdx + 1,
                    endPointIdx: a.endPointIdx + 1,
                    ignoreIndices: a.ignoreIndices.map((i: any) => i + 1),
                  }
                : a
            ),
          },
        };

        return newFeature;
      }

      return feature;
    });

    return this;
  };

  private immutablyAddPosition(
    coordinates: number[] | number[][],
    positionIndexes: number[],
    positionToAdd: number[],
    isPolygonal: boolean
  ): any {
    if (!positionIndexes || !coordinates?.length) {
      return coordinates;
    }

    if (positionIndexes.length === 0) {
      throw Error("Must specify the index of the position to remove");
    }

    if (positionIndexes.length === 1) {
      return [
        ...coordinates.slice(0, positionIndexes[0]),
        positionToAdd,
        ...coordinates.slice(positionIndexes[0]),
      ];
    }

    // recursively update inner array
    return [
      ...coordinates.slice(0, positionIndexes[0]),
      this.immutablyAddPosition(
        coordinates[positionIndexes[0]] as number[],
        positionIndexes.slice(1, positionIndexes.length),
        positionToAdd,
        isPolygonal
      ),
      ...coordinates.slice(positionIndexes[0] + 1),
    ];
  }

  private immutablyRemovePosition(
    coordinates: number[] | number[][],
    positionIndexes: number[],
    isPolygonal: boolean
  ): any {
    if (!positionIndexes) {
      return coordinates;
    }
    if (positionIndexes.length === 0) {
      throw Error("Must specify the index of the position to remove");
    }
    if (positionIndexes.length === 1) {
      if (
        !Array.isArray(coordinates) ||
        positionIndexes[0] >= coordinates.length
      ) {
        console.warn("Invalid position index or coordinates array");
        return coordinates;
      }

      const updated = [
        ...coordinates.slice(0, positionIndexes[0]),
        ...coordinates.slice(positionIndexes[0] + 1),
      ];

      if (
        isPolygonal &&
        (positionIndexes[0] === 0 ||
          positionIndexes[0] === coordinates.length - 1)
      ) {
        // for polygons, the first point is repeated at the end of the array
        // so, if the first/last coordinate is to be removed, coordinates[1] will be the new first/last coordinate
        if (positionIndexes[0] === 0) {
          // change the last to be the same as the first
          updated[updated.length - 1] = updated[0];
        } else if (positionIndexes[0] === coordinates.length - 1) {
          // change the first to be the same as the last
          updated[0] = updated[updated.length - 1];
        }
      }
      return updated;
    }

    // recursively update inner array
    return [
      ...coordinates.slice(0, positionIndexes[0]),
      this.immutablyRemovePosition(
        coordinates[positionIndexes[0]] as number[],
        positionIndexes.slice(1, positionIndexes.length),
        isPolygonal
      ),
      ...coordinates.slice(positionIndexes[0] + 1),
    ];
  }

  // from luma.gl
  private immutablyReplacePosition = (
    coordinates: number[][] | number[],
    positionIndexes: number[],
    updatedPosition: number[],
    isPolygonal: boolean,
    isAdd: boolean = false
  ): any => {
    if (!positionIndexes) {
      return coordinates;
    }

    if (positionIndexes.length === 0) {
      return this.getUpdatedPosition(
        updatedPosition,
        coordinates as number[],
        isAdd
      );
    }

    if (positionIndexes.length === 1) {
      if (
        !Array.isArray(coordinates) ||
        positionIndexes[0] >= coordinates.length
      ) {
        console.warn("Invalid position index or coordinates array");
        return coordinates;
      }

      const updated = [
        ...coordinates.slice(0, positionIndexes[0]),
        this.getUpdatedPosition(
          updatedPosition,
          coordinates[positionIndexes[0]] as number[],
          isAdd
        ),
        ...coordinates.slice(positionIndexes[0] + 1),
      ];

      if (
        isPolygonal &&
        (positionIndexes[0] === 0 ||
          positionIndexes[0] === coordinates.length - 1)
      ) {
        // for polygons, the first point is repeated at the end of the array
        // so, update it on both ends of the array
        updated[0] = this.getUpdatedPosition(
          updatedPosition,
          coordinates[0] as number[],
          isAdd
        );
        updated[coordinates.length - 1] = this.getUpdatedPosition(
          updatedPosition,
          coordinates[0] as number[],
          isAdd
        );
      }
      return updated;
    }

    // recursively update inner array
    return [
      ...coordinates.slice(0, positionIndexes[0]),
      this.immutablyReplacePosition(
        coordinates[positionIndexes[0]] as number[],
        positionIndexes.slice(1, positionIndexes.length),
        updatedPosition,
        isPolygonal,
        isAdd
      ),
      ...coordinates.slice(positionIndexes[0] + 1),
    ];
  };

  private getUpdatedPosition(
    updatedPosition: number[],
    previousPosition: number[],
    isAdd: boolean = false
  ): number[] {
    // This function checks if the updatedPosition is missing elevation
    // and copies it from previousPosition
    if (updatedPosition?.length === 2 && previousPosition?.length === 3) {
      const elevation = previousPosition[2];
      return [
        isAdd ? updatedPosition[0] + previousPosition[0] : updatedPosition[0],
        isAdd ? updatedPosition[1] + previousPosition[1] : updatedPosition[1],
        elevation,
      ];
    }

    return isAdd
      ? [
          updatedPosition[0] + previousPosition[0],
          updatedPosition[1] + previousPosition[1],
        ]
      : updatedPosition;
  }
}

export function getCoordAtPosition(
  coordinates: any,
  positionIndexes: number[]
): any {
  if (positionIndexes.length === 1) {
    return [coordinates[positionIndexes[0]], coordinates.length];
  } else {
    return getCoordAtPosition(
      coordinates[positionIndexes[0]],
      positionIndexes.slice(1, positionIndexes.length)
    );
  }
}

export function isFeatureArea(type: string) {
  return type === GEOJSON_TYPES.Polygon || type === GEOJSON_TYPES.MultiPolygon;
}
export function isFeatureLinear(type: string) {
  return (
    type === GEOJSON_TYPES.LineString || type === GEOJSON_TYPES.MultiLineString
  );
}
export function isFeatureCount(type: string) {
  return type === GEOJSON_TYPES.Point || type === GEOJSON_TYPES.MultiPoint;
}

export function getFeatureMeasures(
  feature: any,
  view: any,
  clMap: any
): IFeatureMeasures | null {
  if (!feature.geometry) {
    return null;
  }

  const type = feature.geometry.type;
  const coords = getCoords(feature.geometry.coordinates);

  const isArea = isFeatureArea(type);
  const isCount = isFeatureCount(type);
  const isLinear = isFeatureLinear(type);

  const isDimensionLine = feature?.properties?.types?.includes(
    GEOJSON_TYPES.dimensionLine
  );

  const featureClass =
    feature?.properties?.className in (clMap || {})
      ? clMap[feature.properties.className]
      : null;

  const featureWidth =
    (isLinear ? featureClass?.thikness || 0 : featureClass?.width || 0) /
    INCHES_TO_FEET;

  const featureLength = (featureClass?.length || 0) / INCHES_TO_FEET;
  const featureHeight = (featureClass?.height || 0) / INCHES_TO_FEET;

  let featureSlopeFactor =
    Math.sqrt(
      Math.pow(featureClass?.verticalSlope || 0, 2) +
        Math.pow(featureClass?.horizontalSlope || 0, 2)
    ) / (featureClass?.horizontalSlope || 1);

  featureSlopeFactor = featureSlopeFactor || 1;

  const tileGrid1 = featureClass?.tileGridVer || 0;
  const tileGrid2 = featureClass?.tileGridHor || 0;
  const tileGridGap = featureClass?.tileGridGap || 0;

  const FeatureAvgGridArea = featureClass?.tileGridEnabled
    ? ((tileGrid1 + tileGridGap) * (tileGrid2 + tileGridGap)) / 144
    : 0;

  const polygon_area = getAreaSquareInches(
    type,
    coords,
    view?.url_dpi || 0,
    view?.scale_real || 0,
    view?.scale_drawing || 0
  );

  const count_area =
    featureClass?.shape === SHAPES.RECTANGLE && featureLength > 0
      ? featureWidth * featureLength
      : featureWidth * featureWidth;

  const polygon_perimeter = getPerimeterInches(
    type == GEOJSON_TYPES.LineString &&
      coords[0].length > 2 &&
      feature?.properties?.arcs?.length > 0 &&
      [MODE_RECTANGLE, MODE_SPHERE].includes(feature?.properties?.arcs[0]?.type)
      ? GEOJSON_TYPES.Polygon
      : type,
    coords,
    view?.url_width || 0,
    view?.bounds || 0,
    view?.url_dpi || 0,
    view?.scale_real || 0,
    view?.scale_drawing || 0
  );

  const length = polygon_perimeter;
  const length_slope = featureSlopeFactor * length;
  const linear_perimeter = length * 2 + featureWidth * 2;
  const count_perimeter =
    featureClass?.shape === SHAPES.RECTANGLE && featureLength > 0
      ? featureWidth * 2 + featureLength * 2
      : featureWidth * 4;

  const area = isArea ? polygon_area : isCount ? count_area : 0;
  const area_slope = featureSlopeFactor * area;

  const perimeter = isDimensionLine
    ? length
    : isArea
    ? polygon_perimeter
    : isLinear
    ? linear_perimeter
    : count_perimeter;

  const perimeter_surface_area = perimeter * featureHeight;
  const volume = isLinear
    ? length * featureHeight * featureWidth
    : area * featureHeight;

  const ver_area_1_side = length * featureHeight;
  const ver_area_2_sides = ver_area_1_side * 2;
  const ver_area_all_sides = isCount
    ? perimeter * featureHeight
    : isLinear
    ? ver_area_2_sides + 2 * featureWidth * featureHeight
    : 0;
  const hor_area = length * featureWidth;

  const tile_count = featureClass?.tileGridEnabled
    ? Math.round(area_slope / FeatureAvgGridArea)
    : 0;

  return {
    area: isArea ? area_slope : area,
    length: isLinear ? length_slope : length,
    volume,
    perimeter: isDimensionLine ? perimeter * INCHES_TO_FEET : perimeter,
    perimeter_surface_area,

    area_slope,
    length_slope,
    tile_count,

    hor_area,
    ver_area_1_side,
    ver_area_2_sides,
    ver_area_all_sides,

    count: 1,
    total_height: featureHeight,
    slope_factor: featureSlopeFactor,
  };
}

export function getMeasureUnit(
  feature: any,
  useMetrics?: boolean,
  measure?: string,
  cl?: any,
  clMap?: any
) {
  const featureClass = !!cl
    ? cl
    : clMap && feature?.properties?.className in clMap
    ? clMap[feature.properties.className]
    : null;

  if (!featureClass) {
    return "";
  }

  const isQuantity = [QUANTITY1, QUANTITY2, QUANTITY3, QUANTITY4].includes(
    measure
  );
  const measureKey = isQuantity ? featureClass[measure] : measure;

  if (measureKey?.includes(QUANTITY_CUTSOM)) {
    return featureClass[QUANTITIES_UOM[measure]];
  }

  const measureUnit = !measureKey
    ? ""
    : isQuantity && featureClass[QUANTITIES_UOM[measure]]
    ? UNITS[featureClass[QUANTITIES_UOM[measure]]]
    : useMetrics
    ? QUANTITIES[measureKey].defaultMetricUnit
    : QUANTITIES[measureKey].defaultUnit;

  return measureUnit?.indicator || "";
}

export function getCustomFeatureMeasure(
  feature: any,
  useMetrics?: boolean,
  measure?: string,
  cl: any = null,
  clMap: any = null
) {
  const featureClass = !!cl
    ? cl
    : clMap && feature?.properties?.className in clMap
    ? clMap[feature.properties.className]
    : null;

  let sanitizedFormula = "";
  const measureFormula = measure + FORMULA_SUFFIX;
  const formula = featureClass?.[measureFormula] || "";
  const { validTokensQuantities } = getValidFormula(
    formula,
    measure,
    featureClass
  );

  for (const token of validTokensQuantities) {
    if (FORMUL_FUNCTIONS_MAP[token]) {
      sanitizedFormula += FORMUL_FUNCTIONS_MAP[token];
    } else if (FORUMULA_FUNCTIONS[token]) {
      try {
        const tokenFunction = FORUMULA_FUNCTIONS[token];
        const tokenValue = tokenFunction(feature, featureClass);
        sanitizedFormula += String(tokenValue);
      } catch (e) {
        console.error("Error in custom formula", e);
        sanitizedFormula += "0";
      }
    } else if (FORMULA_EXPRESSIONS[token]) {
      try {
        const tokenExpression = FORMULA_EXPRESSIONS[token];
        const tokenValue = getFeatureMeasure(
          feature,
          useMetrics,
          false,
          tokenExpression?.measure,
          cl,
          clMap,
          tokenExpression?.unitOverride || null
        );

        sanitizedFormula += String(tokenValue);
      } catch (e) {
        console.error("Error in custom formula", e);
        sanitizedFormula += "0 ";
      }
    } else {
      sanitizedFormula += token;
    }
  }

  sanitizedFormula = sanitizedFormula.replaceAll("=", "");

  try {
    return new Function("return " + sanitizedFormula)();
  } catch (err) {
    console.error("Error executing formula:", sanitizedFormula, err);
    return 0;
  }
}

export function getFeatureMeasure(
  feature: any,
  useMetrics?: boolean,
  returnUnit?: boolean,
  measure?: string,
  cl: any = null,
  clMap: any = null,
  unitOverride: any = null
) {
  const featureClass = !!cl
    ? cl
    : clMap && feature?.properties?.className in clMap
    ? clMap[feature.properties.className]
    : null;

  if (!featureClass || !measure) {
    return getFeatureUnit(feature, useMetrics, returnUnit);
  }

  const isQuantity = [QUANTITY1, QUANTITY2, QUANTITY3, QUANTITY4].includes(
    measure
  );

  const measureKey = isQuantity ? featureClass[measure] : measure;

  const isCustom = measureKey?.includes(QUANTITY_CUTSOM);

  const measureUnit = unitOverride
    ? UNITS[unitOverride]
    : !measureKey
    ? ""
    : isQuantity && featureClass[QUANTITIES_UOM[measure]]
    ? UNITS[featureClass[QUANTITIES_UOM[measure]]]
    : useMetrics
    ? QUANTITIES[measureKey].defaultMetricUnit
    : QUANTITIES[measureKey].defaultUnit;

  const measureValue = isCustom
    ? getCustomFeatureMeasure(feature, useMetrics, measure, cl, clMap)
    : measureKey && measureKey in feature?.properties
    ? feature.properties[measureKey]
    : 0;

  const convertedMeasureValue = isCustom
    ? measureValue
    : measureUnit?.convert
    ? measureUnit.convert(measureValue)
    : measureValue;

  if (convertedMeasureValue) {
    return returnUnit
      ? Intl.NumberFormat(getLocale()).format(
          Math.round(convertedMeasureValue) || 0
        ) +
          " " +
          (measureUnit?.indicator || "").toUpperCase()
      : convertedMeasureValue;
  }

  return 0;
}

export function getFeatureMeasureSum(
  features: any,
  useMetrics?: boolean,
  returnUnit?: boolean,
  measure?: string,
  cl?: any,
  clMap?: any
) {
  if (!features || features?.length === 0) {
    return 0;
  }

  const feature = features[0];
  const featureClass = !!cl
    ? cl
    : clMap && feature?.properties?.className in clMap
    ? clMap[feature.properties.className]
    : null;

  if (!featureClass || !measure) {
    return getFeaturesUnitSum(features, useMetrics, returnUnit);
  }

  const featureUnit = getMeasureUnit(
    feature,
    useMetrics,
    measure,
    featureClass
  );

  const sum = features.reduce(
    (res: number, curr: any) =>
      res + getFeatureMeasure(curr, useMetrics, false, measure, featureClass),
    0
  );

  if (sum) {
    return returnUnit
      ? Intl.NumberFormat(getLocale()).format(Math.round(sum) || 0) +
          " " +
          (featureUnit || "").toUpperCase()
      : sum;
  }

  return 0;
}
