import { LinearInterpolator } from "@deck.gl/core";
import { Feature, FeatureCollection } from "geojson";

import GeoJsonEditMode from "../base/GeojsonEditMode";
import { GEOJSON_TYPES, HOLE_TYPE } from "../../consts/editor";

import {
  getPickedEditHandle,
  getPickedEditHandles,
  getPickedExistingEditHandle,
  getPickedIntermediateEditHandle,
} from "../../utils/modes";
import { createSnapTargets } from "../../utils/coordinates";
import { getEditModeGuides, getEditModesSnapFeatures } from "./utils";
import { getInitialCanvasState, getPanningVector } from "../../utils/autopan";

import {
  initDragging,
  handleMoveVertex,
  handleAddPosition,
  handleMoveFeature,
  getCopyModeGuides,
  handleScaleMarkups,
  handleTranslateEdge,
  handleRemovePosition,
  handleDragAsARectangle,
  getCopyDistanceTooltip,
} from "./editUtils";
import { EditableLayerDraggingEvent } from "../../modes/base/editable-layer";

export class ModifyMode extends GeoJsonEditMode {
  private finalizer: FinalizationRegistry<any>;

  private _isArcMode: boolean;
  private _isScaling: boolean;
  private _isDragging: boolean;
  private _shouldSave: boolean;
  private _isCopyMode: boolean;
  private _isEditHandle: boolean;
  private _isMarkupMode: boolean;
  private _isEdgeTranslate: boolean;
  private _isGeometryTranslate: boolean;
  private _isRectTranslateMode: boolean;
  private _is3dMeshInvalidated: boolean;

  private _arcs: any[];
  private _snapped: any;
  private _updatedData: any[];
  private indexedTargets: any;
  private _dragginFeature: any;
  private _copyModeDetails: any;

  private _lastDrag: any;
  private _panState: any;
  private _panAnimationProps: any;

  private _closestPoints: any[];
  private _cornerGuidePoints: any[];
  private _cornerGuidePointsB: any[];
  private _rectShapeBeforeEdit: any;
  private _selectedEditHandle: null;
  private _geometryBeforeTranslate: any;
  private _geometryBeingScaled: FeatureCollection;

  constructor() {
    super();

    this._isArcMode = false;
    this._isScaling = false;
    this._shouldSave = false;
    this._isDragging = false;
    this._isEditHandle = false;
    this._isMarkupMode = false;
    this._isEdgeTranslate = false;
    this._isGeometryTranslate = false;
    this._isRectTranslateMode = false;

    this._snapped = null;

    this._closestPoints = [];
    this._updatedData = [];

    this._panState = null;
    this.indexedTargets = null;
    this._dragginFeature = null;
    this._cornerGuidePoints = null;
    this._selectedEditHandle = null;
    this._geometryBeingScaled = null;
    this._rectShapeBeforeEdit = null;
    this._geometryBeforeTranslate = null;
  }

  _createSnapTargets(props: any, updatedData: any[] = []) {
    const updatedGeoJson = props.modeConfig.geoJson.map(
      (oldFeature: Feature) =>
        updatedData?.find(
          (newFeature) =>
            newFeature?.properties?.id === oldFeature?.properties?.id
        ) ?? oldFeature
    );

    this.indexedTargets = createSnapTargets(updatedGeoJson, []);
  }

  startAnimationLoop = () => {
    super.startAnimationLoop();
  };

  // edited from  : https://github.com/uber/nebula.gl/blob/master/modules/edit-modes/src/lib/modify-mode.ts
  getGuides = (props: any) => {
    const handles = getEditModeGuides(props, this);

    if (!this.indexedTargets && props?.modeConfig?.geoJson?.length > 0) {
      this._createSnapTargets(props);
    }

    const snapFeatures = getEditModesSnapFeatures(props, this);

    if (snapFeatures.length > 0) {
      handles.features.push(...snapFeatures);
    }

    if (this._isCopyMode && !!this._copyModeDetails) {
      const copyModeguides = getCopyModeGuides(this);
      handles.features.push(...copyModeguides);
    }

    return handles;
  };

  get3dGuides = (props: any) => {
    const {
      is3dMode,
      meshData,
      staticData,
      colorMap,
      referenceInchesPerPixels,
    } = props.modeConfig;

    if (!is3dMode) return null;

    const guides3dMeshes = [];

    if (meshData) {
      guides3dMeshes.push(meshData);
    }

    const data = [...props.data?.features];

    if (staticData?.length > 0) {
      data.push(...staticData);
    }

    if (data?.length === 0) return null;

    const dataState = {
      dataL: props.data?.features?.length || 0,
      staticDataL: staticData?.length || 0,
    };

    const didStateChange =
      dataState?.dataL !== this._previousState?.dataL ||
      dataState?.staticDataL !== this._previousState?.staticDataL;

    if (didStateChange) {
      this._previousState = dataState;
    }

    if (
      (!this._isPreparingMesh3d && (!this._mesh3d || didStateChange)) ||
      this._is3dMeshInvalidated
    ) {
      this._is3dMeshInvalidated = false;
      this.generateMesh(data, colorMap, referenceInchesPerPixels);
    }

    if (this._mesh3d) {
      guides3dMeshes.push(this._mesh3d);
    }

    return guides3dMeshes;
  };

  getTooltips = (props: any) => {
    if (this._isCopyMode && !!this._copyModeDetails) {
      const distanceTooltips = getCopyDistanceTooltip(props, this);

      return [...distanceTooltips];
    }

    return [];
  };

  handleClick = (event: any, props: any) => {
    const pickedExistingHandle = getPickedExistingEditHandle(event.picks);
    const pickedIntermediateHandle = getPickedIntermediateEditHandle(
      event.picks
    );

    if (pickedExistingHandle) {
      const isMetaKey =
        event?.sourceEvent?.altKey || event?.sourceEvent?.metaKey;

      handleRemovePosition(props, pickedExistingHandle, isMetaKey, this);
      this._is3dMeshInvalidated = true;
      this._createSnapTargets(props);
    } else if (pickedIntermediateHandle) {
      handleAddPosition(props, pickedIntermediateHandle, this);
      this._is3dMeshInvalidated = true;
      this._createSnapTargets(props);
    }
  };

  handleDblClick = (event: any, props: any) => {
    const {
      modeConfig: { setActiveClassification, colorMap },
    } = props;
    let selectedFeature: Feature = this.getSelectedFeature(props);
    const pickedExistingHandle = getPickedExistingEditHandle(event.picks);
    const pickedIntermediateHandle = getPickedIntermediateEditHandle(
      event.picks
    );

    if (pickedExistingHandle || pickedIntermediateHandle) return;

    const currentClassification =
      colorMap &&
      selectedFeature?.properties?.className in colorMap &&
      colorMap[selectedFeature?.properties?.className];

    if (
      currentClassification &&
      !!setActiveClassification &&
      !selectedFeature.properties.types.includes(GEOJSON_TYPES.dimensionLine) &&
      !selectedFeature.properties.types.includes(GEOJSON_TYPES.markup)
    ) {
      setActiveClassification(currentClassification);
    }
  };

  handleStartDragging = (event: any, props: any) => {
    const { isFocused, useCanvasPan, canvasContext } = props.modeConfig;
    this._isDragging = true;

    if (this._cornerGuidePoints) {
      this._cornerGuidePointsB = this._cornerGuidePoints;
    }

    if (
      isFocused ||
      useCanvasPan ||
      (canvasContext && !canvasContext?.isMarkup)
    ) {
      this._panState = null;
      return;
    }

    this._panState = getInitialCanvasState();

    initDragging(event, props, this);

    this._enableEdgePanning(this.handleDragging.bind(this));
  };

  getMapCoords(deckContext: any, screenCoords: any) {
    const viewport = deckContext?.viewManager?._viewports[0];
    return viewport.unproject([screenCoords?.[0] || 0, screenCoords?.[1] || 0]);
  }

  handleDragging = (
    event: EditableLayerDraggingEvent,
    props: any,
    manual?: boolean
  ) => {
    this._lastDrag = performance.now();

    const { deck, isFocused, useCanvasPan, viewState, setViewState } =
      props.modeConfig;
    const deckContext = deck?.current?.deck;
    const mapCoords = this.getMapCoords(deckContext, event.screenCoords);

    if (manual && mapCoords) {
      event.mapCoords = mapCoords;
    }

    const editHandle = getPickedEditHandle(event.pointerDownPicks);

    if (isFocused || useCanvasPan) {
      return;
    }

    if (!event.passive) {
      this._updateEdgePanning(event, props, manual);
    }

    if (!manual && this._panState && !!deckContext) {
      const panProps = getPanningVector(event, this._panState, deckContext);

      if (panProps.shouldUpdate) {
        setViewState({
          ...viewState,
          zoom: panProps.zoom,
          target: panProps.target,
          transitionDuration: 5,
          transitionInterpolator: new LinearInterpolator(["target", "zoom"]),
        });
      } else {
        this._panAnimationProps = null;
      }
    }

    const shouldDragAsARect =
      this._isRectTranslateMode &&
      editHandle &&
      !this._isEdgeTranslate &&
      (event.sourceEvent.altKey || event.sourceEvent.metaKey);

    if (shouldDragAsARect) {
      this._shouldSave = true;
      handleDragAsARectangle(event, props, this);

      return;
    }

    if (this._isGeometryTranslate) {
      this._shouldSave = true;
      event.cancelPan();

      const isShiftKey = event?.sourceEvent?.shiftKey;

      let diffX = event.mapCoords[0] - event.pointerDownMapCoords[0];
      let diffY = event.mapCoords[1] - event.pointerDownMapCoords[1];

      if (isShiftKey) {
        if (Math.abs(diffX) > Math.abs(diffY)) {
          diffY = 0;
        }
        if (Math.abs(diffY) > Math.abs(diffX)) {
          diffX = 0;
        }
      }

      if (this._isCopyMode) {
        this._copyModeDetails = {
          diffX,
          diffY,
        };
      } else {
        this._copyModeDetails = null;
      }

      handleMoveFeature(props, diffX, diffY, this);
      return;
    }

    if (this._isMarkupMode && !this._isScaling) {
      return;
    }

    if (this._isMarkupMode && this._isScaling) {
      this._shouldSave = true;
      event.cancelPan();

      handleScaleMarkups(event, props, this);

      return;
    }

    if (this._isEdgeTranslate) {
      this._shouldSave = true;
      event.cancelPan();

      handleTranslateEdge(event, props, this);

      return;
    }

    if (editHandle) {
      this._shouldSave = true;
      this._isEditHandle = true;
      event.cancelPan();

      handleMoveVertex(event, props, this);
    }
  };

  handleStopDragging = (_event: any, props: any) => {
    this._panState = null;
    this._panAnimationProps = null;

    this._isDragging = false;
    this._createSnapTargets(props, this._updatedData);

    if (this._isScaling) {
      this._isScaling = false;
      this._selectedEditHandle = null;
      this._geometryBeingScaled = null;
    }

    this._isMarkupMode = false;
    this._isEditHandle = false;
    this._isCopyMode = false;
    this._isEdgeTranslate = false;
    this._isRectTranslateMode = false;
    this._isGeometryTranslate = false;

    this._arcs = [];
    this._snapped = null;
    this._updatedData = [];
    this._dragginFeature = null;
    this._copyModeDetails = null;
    this._cornerGuidePointsB = [];
    this._rectShapeBeforeEdit = null;
    this._geometryBeforeTranslate = null;

    if (this._shouldSave) {
      this._is3dMeshInvalidated = true;

      this.applyActions(props, true, {
        isCopyMode: this._isCopyMode,
        ignoreClick: true,
      });
    }

    this._disableEdgePanning();
  };

  handleKeyDown = (event: any, props: any) => {
    if (props?.data?.features?.length > 0) {
      const markupFeature = props?.data?.features.find((f: any) =>
        f?.properties?.types?.includes(GEOJSON_TYPES.markup)
      );
      const isMarkup = !!markupFeature;
      const isMarkupText =
        isMarkup &&
        !!(
          markupFeature?.properties?.correspondingLineId ||
          markupFeature?.properties?.correspondingTextId
        );

      if (
        !isMarkupText &&
        ["ArrowRight", "ArrowUp", "ArrowDown", "ArrowLeft"].includes(event.key)
      ) {
        event.preventDefault();
        event.stopPropagation();

        let diffX =
          event.key === "ArrowRight" ? 1 : event.key === "ArrowLeft" ? -1 : 0;
        let diffY =
          event.key === "ArrowUp" ? -1 : event.key === "ArrowDown" ? 1 : 0;

        if (event?.shiftKey) {
          diffX *= 10;
          diffY *= 10;
        }

        this._geometryBeforeTranslate =
          this.getSelectedFeaturesAsFeatureCollection(props);
        handleMoveFeature(props, diffX, diffY, this);
        this._geometryBeforeTranslate = null;
        this.applyActions(props, true);

        this._is3dMeshInvalidated = true;
        return;
      }

      if (event.key === "Shift" && this._isDragging) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }

      if (
        this._isDragging &&
        !isMarkup &&
        (event.key === "a" || event.key === "A")
      ) {
        event.preventDefault();
        event.stopPropagation();

        this._isArcMode = true;
      }

      if (event.key === "Alt") {
        event.preventDefault();
        event.stopPropagation();

        const features = this.getSelectedFeaturesAsFeatureCollection(props);

        const ignoreTypes: string[] = [
          GEOJSON_TYPES.hole,
          GEOJSON_TYPES.markup,
          GEOJSON_TYPES.breakdown,
          GEOJSON_TYPES.markupGroup,
          GEOJSON_TYPES.dimensionLine,
        ];

        const filtered = features?.features?.filter((feature: Feature) =>
          feature?.properties?.types?.some((t: string) =>
            ignoreTypes.includes(t)
          )
        );
        const isHole = features?.features?.some((feature: Feature) =>
          feature?.properties?.id.includes(HOLE_TYPE)
        );

        if (
          filtered?.length === 0 &&
          (!isHole || (isHole && features?.features?.length > 1))
        ) {
          this._isCopyMode = true;
        }
      }
    }
  };

  handleKeyUp = (event: any, props: any) => {
    if (props?.data?.features?.length > 0) {
      const isMarkup = !!props?.data?.features.find((f: any) =>
        f?.properties?.types?.includes(GEOJSON_TYPES.markup)
      );
      if (
        this._isDragging &&
        !isMarkup &&
        (event.key === "a" || event.key === "A")
      ) {
        this._isArcMode = false;
      }

      if (event.key === "Alt") {
        this._isCopyMode = false;
      }
    }
  };

  handlePointerMove = (event: any, props: any) => {
    const { modeConfig } = props;

    if (modeConfig.useSelectionBox) {
      props.onUpdateCursor("crosshair");
      return;
    }

    if (this._isGeometryTranslate) {
      props.onUpdateCursor("move");
      return;
    }

    if (this._isMarkupMode && !this._isScaling) {
      const selectedEditHandle = getPickedEditHandle(event.picks);
      this._selectedEditHandle =
        selectedEditHandle &&
        selectedEditHandle.properties.editHandleType === "scale"
          ? selectedEditHandle
          : null;
    }

    const picks = (event && event.picks) || [];

    const handlesPicked = getPickedEditHandles(picks);
    if (handlesPicked.length) {
      props.onUpdateCursor("crosshair");
      return;
    }

    props.onUpdateCursor("default");
    return;
  };

  animate() {
    // if (!!this._panAnimationProps) {
    //   const lastDragDiff = performance.now() - this._lastDrag;
    //   const { deckContext, panX, panY, viewState, setViewState } =
    //     this._panAnimationProps;
    //   const viewport = deckContext?.viewManager?._viewports[0];
    //   const zoom = viewport ? viewport.zoom : 0;
    //   let targetX = viewport.position[0];
    //   let targetY = viewport.position[1];
    //   const target = [targetX + panX, targetY + panY, 0];
    //   setViewState({
    //     ...viewState,
    //     zoom,
    //     target,
    //     transitionDuration: 5,
    //     transitionInterpolator: new LinearInterpolator(["target"]),
    //   });
    //   if (lastDragDiff > 20) {
    //     this.handleDragging(
    //       this._panAnimationProps.event,
    //       this._panAnimationProps.props,
    //       true
    //     );
    //   }
    // }
  }
}
