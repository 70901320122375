import request from "../request";
import { http } from "src/constants/http";
import { doInfiniteRequest } from "src/constants/request";
import { withGlobalOrg, withGlobalOrgWhere } from "./_helpers";

export async function create(values) {
  try {
    const { body } = await request.post("set", {
      body: withGlobalOrg(values),
    });

    return body;
  } catch (e) {
    if (e.status === http.CONFLICT) {
      e.message = `Can't create a set named "${values.name}". A set with this name already exists.`;
    }
    throw e;
  }
}

export async function get({ id, query }) {
  const { body } = await request.get(`set/${id}`, { query });
  return body;
}

export async function duplicate({ id }) {
  const { body } = await request.post(`set/${id}/copy`);
  return body;
}

export async function unlock({ id }) {
  const { body } = await request.post(`set/${id}/unlock`);
  return body;
}

export async function list(query) {
  const { body } = await request.get("set", {
    query: withGlobalOrgWhere(query),
  });
  return body;
}

export async function listAll(query) {
  return await doInfiniteRequest("set", query);
}

export async function update({ id, ...values }) {
  const { body } = await request.put(`set/${id}`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function remove({ id }) {
  const { body } = await request.delete(`set/${id}`);
  return body;
}

export async function addPages({ id, ...values }) {
  const { body } = await request.post(`set/${id}/pages`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function addPage({ id, pageId }) {
  const { body } = await request.post(`set/${id}/page/${pageId}`);
  return body;
}

export async function setSort({ id, sortedData }) {
  const { body } = await request.post(`set/${id}/sort`, {
    body: [...sortedData],
  });
  return body;
}

export async function setClassificationsOrder({ id, classification_order }) {
  const { body } = await request.put(`set/${id}/classification/order`, {
    body: classification_order,
  });
  return body;
}

export async function setBreakdownsOrder({ id, breakdown_order }) {
  const { body } = await request.put(`set/${id}/breakdown/order`, {
    body: breakdown_order,
  });
  return body;
}

export async function addUsersInvite({ id, userId, ...values }) {
  const { body } = await request.post(`set/${id}/invite/${userId}`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function updateUsersInvite({ id, userId, ...values }) {
  const { body } = await request.put(`set/${id}/invite/${userId}`, {
    body: withGlobalOrg(values),
  });
  return body;
}

export async function removeUserInvite({ id, userId }) {
  const { body } = await request.delete(`set/${id}/invite/${userId}`);
  return body;
}

export async function searchTextInSet({ id, textSearch }) {
  const queryParams = textSearch.map((term) => `search=${term}`).join("&");
  const { body } = await request.get(`set/${id}/text-search?${queryParams}`);
  return body;
}

export async function searchText({ id, textSearch, pageId }) {
  const queryParams = `${textSearch
    .map((term) => `search=${term}`)
    .join("&")}&page_id=${pageId}`;
  const { body } = await request.get(`set/${id}/text-search?${queryParams}`);
  return body;
}

export async function searchClassification(setId, classificationId) {
  const { body } = await request.get(
    `set/${setId}/classification/search?search=${classificationId}`
  );
  return body;
}
