import request from "../request";

export async function exportPdf(view_id, query) {
  return await request.post(`view/${view_id}/export`, {
    body: { clMap: query },
  });
}

export async function exportPdfMultipage(body) {
  return await request.post(`/view/export_multipage`, { body });
}

export async function getExportPdfByPageId(view_id) {
  return await request.get(`view/${view_id}/export`);
}

export async function getDrawingLogBySetRequest(set_id, organization_id) {
  const { body } = await request.post(`export_draw_log`, {
    body: { set_id, organization_id },
  });

  return body;
}

export async function getDrawingLogBySet(set_id, organization_id) {
  const { body } = await getDrawingLogBySetRequest(set_id, organization_id);

  return body;
}

export async function getDrawingLogByPagesRequest(page_ids, organization_id) {
  const { body } = await request.post(`export_draw_log`, {
    body: { page_ids, organization_id },
  });

  return body;
}

export async function getDrawingLogByPages(page_ids, organization_id) {
  const { body } = await getDrawingLogByPagesRequest(page_ids, organization_id);

  return body;
}
