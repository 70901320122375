import { cleanCls } from "../Editor/data";

import * as classificationsFoldersApi from "src/lib/api/folders";
import * as classificationsApi from "src/lib/api/classifications";
import * as classificationsSetsApi from "src/lib/api/classificationsSet";

import {
  DEFAULT_COLOR,
  CLASSIFICATION_CATEGORIES,
  CLASSIFICATIONS_CHANGE_TYPES,
  DEFAULT_CLASSIFICATION_IDS_SET,
} from "sf/consts/editor";
import {
  addClassification,
  removeClassificationById,
  updateClassificationById,
} from "../Editor/utils";
import { DEFAULT_API_LIMIT } from "src/lib/utils";

export function getSortedData(data) {
  return data.sort((a, b) => {
    const aDate = new Date(a.updated);
    const bDate = new Date(b.updated);
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });
}

export function getClsFromApi(cls) {
  return {
    ...cls.data,
    id: cls.id,
    children: [],
    label: cls.name,
    category: CLASSIFICATION_CATEGORIES[0],
    organizationId: cls.organization_id,
    folderId: cls.classification_folder_id ?? null,
    classificationSetId: cls.classification_set_id,
    classificationCustomId: cls.data?.classificationCustomId || "",
  };
}

export function getFolderFromApi(cls) {
  return {
    id: cls.id,
    label: cls.name,
    children: [],
    category: CLASSIFICATION_CATEGORIES[2],
    organizationId: cls.organization_id,
    folderId: cls.classification_folder_id ?? null,
    colorStyle: DEFAULT_COLOR,
    classificationSetId: cls.classification_set_id,
    classificationCustomId: cls?.classification_custom_id || "",
  };
}

export function getClsApiFromCls(
  cls,
  organization_id,
  classification_set_id = null,
  set_id = null,
  user_id = null
) {
  return {
    id: cls.id,
    set_id,
    user_id,
    organization_id,
    name: cls.label || "",
    classification_set_id,
    classification_folder_id:
      cls?.folderId && !DEFAULT_CLASSIFICATION_IDS_SET.has(cls.folderId)
        ? cls.folderId
        : null,
    data: {
      ...cls,
      children: [],
      type: cls.type,
      category: cls.category,
      height: cls.height || 0,
      colorStyle: cls.colorStyle,
    },
  };
}

export function getLibraryFolderApiFromCls(
  cls,
  organization_id,
  classification_set_id,
  user_id = null
) {
  return {
    id: cls.id,
    set_id: null,
    user_id,
    name: cls.label || "",
    organization_id,
    classification_set_id,
    classification_folder_id: cls?.folderId || null,
    classification_custom_id: cls.classificationCustomId || "",
  };
}

export function getFolderApiFromCls(cls, organization_id, set_id) {
  return {
    id: cls.id,
    set_id,
    user_id: null,
    organization_id,
    name: cls.label || "",
    classification_set_id: null,
    classification_folder_id: cls?.folderId || null,
    classification_custom_id: cls.classificationCustomId || "",
  };
}

export async function getClassificationSet(id, onError) {
  try {
    return await classificationsSetsApi.getClassificationSetById(id);
  } catch (e) {
    onError(e);
    return null;
  }
}

export async function getAllClassificationSets(
  organization_id,
  data = [],
  offset = 0,
  onError
) {
  let finalData = data;

  try {
    const res = await classificationsSetsApi.listClassificationSets({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: { organization_id },
    });

    finalData = [...finalData, ...res.rows];

    if (res.count > offset) {
      return await getAllClassificationSets(
        organization_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError
      );
    }

    return finalData;
  } catch (e) {
    onError(e);
    return finalData;
  }
}

export async function getAllClassificationSetsAndClassifications(
  organization_id,
  onError
) {
  try {
    const [
      classificationSets,
      allSetClassifications,
      allSetClassificationsFolders,
    ] = await Promise.all([
      getAllClassificationSets(organization_id, [], 0, onError),
      getAllClassificationSetClassifications(
        organization_id,
        { $ne: null },
        [],
        0,
        onError
      ),
      getAllClassificationSetFolders(
        organization_id,
        { $ne: null },
        [],
        0,
        onError
      ),
    ]);

    const cleanedClassifications = allSetClassifications.map(cleanCls);

    const folderMap = new Map();
    allSetClassificationsFolders.forEach((folder) => {
      if (!folderMap.has(folder.classificationSetId)) {
        folderMap.set(folder.classificationSetId, new Map());
      }
      folderMap
        .get(folder.classificationSetId)
        .set(folder.id, { ...folder, children: [] });
    });

    const classificationsMap = new Map();
    cleanedClassifications.forEach((cls) => {
      if (!classificationsMap.has(cls.classificationSetId)) {
        classificationsMap.set(cls.classificationSetId, []);
      }
      classificationsMap.get(cls.classificationSetId).push(cls);
    });

    classificationSets.forEach((classificationSet) => {
      const setClassifications =
        classificationsMap.get(classificationSet.id) || [];
      const setFoldersMap = folderMap.get(classificationSet.id) || new Map();

      setClassifications.forEach((cls) => {
        if (cls.folderId) {
          const folder = setFoldersMap.get(cls.folderId);
          if (folder) {
            folder.children.push(cls);
          }
        }
      });

      const result = [];
      setFoldersMap.forEach((folder) => {
        if (!folder.folderId) {
          result.push(folder);
        } else {
          const parentFolder = setFoldersMap.get(folder.folderId);
          if (parentFolder) {
            if (!parentFolder.children) {
              parentFolder.children = [];
            }
            parentFolder.children.push(folder);
          }
        }
      });

      result.push(...setClassifications.filter((cls) => !cls.folderId));

      classificationSet.classifications = result;
    });

    return classificationSets;
  } catch (e) {
    console.error("error : ", e);
    onError(e);
    return [];
  }
}

export async function handleCreateClassificationSet(
  set,
  success,
  error,
  successCb
) {
  try {
    const res = await classificationsSetsApi.addClassificationSet(set);
    success({
      children: "Classification set created successfully!",
    });

    successCb && successCb(res);
  } catch (e) {
    console.error("error : ", e);
    error({
      children: e?.message || JSON.stringify(e),
    });
  }
}

export async function handleEditClassificationSet(
  updatedSet,
  success,
  error,
  successCb
) {
  try {
    const res = await classificationsSetsApi.updateClassificationSetById(
      updatedSet
    );

    success &&
      success({
        children: "Classification set edited successfully!",
      });
    successCb && successCb(res);
  } catch (e) {
    console.error("error : ", e);
    error &&
      error({
        children: e?.message || JSON.stringify(e),
      });
  }
}

export async function handleDuplicateClassificationSet(
  newSet,
  sourceId,
  succes,
  error,
  successCb
) {
  try {
    const res = await classificationsSetsApi.addClassificationSet(newSet);
    if (res.id) {
      try {
        await classificationsSetsApi.duplicateClassificationsFromSet(
          sourceId,
          res.id
        );
        succes({
          children: "Classification set duplicated successfully!",
        });
      } catch (e) {
        console.error("error : ", e);
        error({
          children: e?.message || JSON.stringify(e),
        });
      }
    }

    successCb && successCb(res);
  } catch (e) {
    console.error("error : ", e);
    error({
      children: e?.message || JSON.stringify(e),
    });
  }
}

export async function handleDeleteClassificationSet(
  id,
  success,
  error,
  successCb
) {
  try {
    await classificationsSetsApi.removeClassificationSetById(id);
    success({
      children: "Selected classification sets deleted succesfully!",
    });

    successCb && successCb(id);
  } catch (e) {
    console.error("error : ", e);

    error({
      children: e?.message || JSON.stringify(e),
    });
  }
}

export async function handleDeleteSelectedClassificationSets(
  selected,
  success,
  error,
  successCb
) {
  for (const id of selected) {
    try {
      await classificationsSetsApi.removeClassificationSetById(id);
      success({
        children: "Selected classification sets deleted succesfully!",
      });

      successCb && successCb();
    } catch (e) {
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }
}

export async function getAllClassificationSetClassifications(
  organization_id,
  classification_set_id,
  data = [],
  offset = 0,
  onError
) {
  let finalData = data;

  try {
    const res = await classificationsApi.listClassifications({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: { organization_id, classification_set_id },
    });

    finalData = [...finalData, ...res.rows.map(getClsFromApi)];

    if (res.count > offset) {
      return await getAllClassificationSetClassifications(
        organization_id,
        classification_set_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError
      );
    }

    return finalData;
  } catch (e) {
    onError(e);
    return finalData;
  }
}

export async function getAllClassificationSetFolders(
  organization_id,
  classification_set_id,
  data = [],
  offset = 0,
  onError,
  sortConfig = null
) {
  let finalData = data;

  try {
    const res = await classificationsFoldersApi.listFolders({
      $offset: offset,
      $limit: DEFAULT_API_LIMIT,
      $where: {
        organization_id,
        classification_set_id,
        ...(sortConfig ? sortConfig?.$where : {}),
      },
      $order: sortConfig ? sortConfig?.$order : null,
    });

    finalData = [...finalData, ...res.rows.map(getFolderFromApi)];

    if (res.count > offset) {
      return await getAllClassificationSetFolders(
        organization_id,
        classification_set_id,
        finalData,
        offset + DEFAULT_API_LIMIT,
        onError,
        sortConfig
      );
    }

    return finalData;
  } catch (e) {
    onError(e);
    return finalData;
  }
}

async function handleNestedFolder(
  parentFolder,
  folderData,
  organizationId,
  setId,
  userId,
  classifications
) {
  let newClassifications = classifications;

  const newFolder = getLibraryFolderApiFromCls(
    folderData,
    organizationId,
    setId,
    userId
  );

  if (parentFolder) {
    newFolder.classification_folder_id = parentFolder.id;
  }
  delete newFolder.id;

  const folderRes = await classificationsFoldersApi.addFolder(newFolder);
  const folder = getFolderFromApi(folderRes);
  newClassifications = [...newClassifications, folder];

  if (folderData?.children?.length > 0) {
    for (const child of folderData.children) {
      if (child.category === CLASSIFICATION_CATEGORIES[2]) {
        newClassifications = await handleNestedFolder(
          folder,
          child,
          organizationId,
          setId,
          userId,
          newClassifications
        );
      } else {
        const newCls = getClsApiFromCls(
          child,
          organizationId,
          setId,
          null,
          userId
        );
        newCls.classification_folder_id = folder.id;
        delete newCls.id;

        const clsRes = await classificationsApi.addClassification(newCls);
        const cls = getClsFromApi(clsRes);
        newClassifications = addClassification(
          newClassifications,
          folder,
          cls,
          folder.id
        );
      }
    }
  }

  return newClassifications;
}

export async function handleClassificationsApi(
  organizationId,
  setId,
  userId,
  classifications,
  change,
  success,
  error
) {
  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.add) {
    let newCl = classifications;

    const preparedChangeFolders = change?.folders
      ? change.folders
      : change?.folder && !change?.cls
      ? [change.folder]
      : [];

    const preparedChangeCls = change?.cls ? [change.cls].flat() : [];

    if (preparedChangeFolders?.length) {
      for (const changeFolder of preparedChangeFolders) {
        try {
          newCl = await handleNestedFolder(
            null,
            changeFolder,
            organizationId,
            setId,
            userId,
            newCl
          );
        } catch (e) {
          console.error("error : ", e);
          error({
            children: e?.message || JSON.stringify(e),
          });
        }
      }
    }

    if (preparedChangeCls?.length) {
      for (const cl of preparedChangeCls) {
        try {
          const newCls = getClsApiFromCls(
            cl,
            organizationId,
            setId,
            null,
            userId
          );
          delete newCls.id;

          if (change?.folder?.id) {
            newCls.classification_folder_id = change.folder.id;
          }

          const res = await classificationsApi.addClassification(newCls);
          const cls = getClsFromApi(res);

          newCl = addClassification(
            newCl,
            change.folder,
            cls,
            change.folder?.id
          );
        } catch (e) {
          console.error("error : ", e);
          error({
            children: e?.message || JSON.stringify(e),
          });
        }
      }
    }

    success && success(newCl);
  }

  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.delete) {
    try {
      await classificationsApi.removeClassificationById(change.cls.id);

      const newCl = removeClassificationById(classifications, change.cls.id);
      success && success(newCl);
    } catch (e) {
      console.error("error : ", e);
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }

  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.deleteFolder) {
    try {
      await classificationsFoldersApi.removeFolderById(change.folder.id);
      const newCl = removeClassificationById(classifications, change.folder.id);
      success && success(newCl);
    } catch (e) {
      console.error("error : ", e);
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }

  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.update) {
    try {
      const updatedCls = getClsApiFromCls(
        change.cls,
        organizationId,
        setId,
        null,
        userId
      );
      if (change.folder?.id) {
        updatedCls.classification_folder_id = change.folder.id;
      }

      const res = await classificationsApi.updateClassificationById(updatedCls);
      const cls = getClsFromApi(res);

      const newCl = updateClassificationById(classifications, cls);
      success && success(newCl);
    } catch (e) {
      console.error("error : ", e);
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }

  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.updateFolder) {
    try {
      const newFolder = getLibraryFolderApiFromCls(
        change.folder,
        organizationId,
        setId,
        userId
      );
      const res = await classificationsFoldersApi.updateFolderById(newFolder);
      const folder = {
        ...getFolderFromApi(res),
        children: change.folder.children,
      };

      const newCl = updateClassificationById(classifications, folder);
      success && success(newCl);
    } catch (e) {
      console.error("error : ", e);
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }

  if (change.type === CLASSIFICATIONS_CHANGE_TYPES.move) {
    try {
      if (change.cls.category === CLASSIFICATION_CATEGORIES[2]) {
        const updatedFolder = getLibraryFolderApiFromCls(
          change.cls,
          organizationId,
          setId,
          userId
        );

        if (change.folder?.id && !change.folder.folderId) {
          updatedFolder.classification_folder_id = change.folder.id;
        } else {
          updatedFolder.classification_folder_id = null;
        }

        const res = await classificationsFoldersApi.updateFolderById(
          updatedFolder
        );
        const folder = getFolderFromApi(res);

        let newCl = removeClassificationById(classifications, folder.id);
        newCl = addClassification(
          newCl,
          change.folder,
          folder,
          change.folder?.id
        );
        success && success(newCl);
      } else {
        const updatedCls = getClsApiFromCls(
          change.cls,
          organizationId,
          setId,
          null,
          userId
        );

        if (change.folder?.id) {
          updatedCls.classification_folder_id = change.folder.id;
        } else {
          updatedCls.classification_folder_id = null;
        }

        const res = await classificationsApi.updateClassificationById(
          updatedCls
        );
        const cls = getClsFromApi(res);

        let newCl = removeClassificationById(classifications, cls.id);
        newCl = addClassification(newCl, change.folder, cls, change.folder?.id);
        success && success(newCl);
      }
    } catch (e) {
      console.error("error : ", e);
      error({
        children: e?.message || JSON.stringify(e),
      });
    }
  }
}
