import qs from "qs";
import urlJoin from "url-join";

import { ERRORS_LOCALES } from "sf/i18n";
import { sleep } from "sf/utils/function";
import { generateId } from "sf/utils/string";

import config from "src/config";
import { ApiError, ApiResponse } from "./types";
import { getSessionId, getUserLocale } from "src/modules/Auth/context";

const DEFAULT_RETRY_DELAY = 1000;
const HTTP_METHODS = ["get", "post", "put", "patch", "head", "delete"];

const request = HTTP_METHODS.reduce((reqMethods, method) => {
  reqMethods[method] = (path: string, options = {}) =>
    requestFn(path, { ...options, method: method.toUpperCase() });

  return reqMethods;
}, {});

function debugApiError(
  context: string,
  error: any,
  details: Record<string, any> = {}
) {
  console.error(`🔴 API Error [${context}]: ${details?.requestId || ""}`, {
    error,
    message: error?.message || error || "Unknown error",
    location: window?.location?.href,
    errorCode:
      error?.body?.error?.errorCode || error?.errorCode || "Unknown error code",
    path: details?.path || "Unknown path",
    method: details?.method || "Unknown method",
    requestBody: details?.body || "Unknown request body",
    requestHeaders: details?.headers || "Unknown request headers",
    responseStatus: details?.status || "Unknown response status",
    responseBody: error?.body || "Unknown response body",
    stack: error?.stack || "Unknown stack trace",
    ...details,
  });
}

function serializeQueryParams(query: {
  $order?: string | [string, "asc" | "desc"];
  $where?: string | Record<string, any>;
  $attributes?: string | Record<string, any>;
}) {
  const serializedQuery = { ...query };

  if (Array.isArray(serializedQuery.$order)) {
    const [by, direction] = serializedQuery.$order;
    serializedQuery.$order = `[["${by}","${direction.toUpperCase()}"]]`;
  }

  ["$where", "$attributes"].forEach((key) => {
    if (!serializedQuery[key]) return;
    serializedQuery[key] = JSON.stringify(serializedQuery[key]);
  });

  return qs.stringify(serializedQuery) as string;
}

async function parseResponse(response: Response): Promise<ApiResponse> {
  const contentType = response.headers.get("Content-Type")?.toLowerCase() || "";
  return contentType.includes("application/json")
    ? ((await response.json()) as ApiResponse)
    : { body: await response.text(), error: null };
}

function handleCustomError(
  response: Record<string, any>,
  localeErrors: Record<number, string>
): Error {
  const apiError = response.body?.error as ApiError | undefined;
  const errorCode = apiError?.errorCode as number | undefined;
  const errorMessage =
    apiError?.message || localeErrors[errorCode] || localeErrors[4001];
  const error = new Error(errorMessage);
  return Object.assign(error, response);
}

async function requestFn(
  path: string,
  options: RequestInit & {
    body?: Record<string, any> | string;
    query?: Record<string, any>;
    headers?: Record<string, string>;
    maxRetries?: number;
    retryDelay?: number;
    requestId?: string;
  } = {},
  attemptCount = 0
) {
  const originalOptions = { ...options };

  const session: string = getSessionId();
  const localeErrors: Record<number, string> = getUserLocale()[ERRORS_LOCALES];

  const maxRetries = originalOptions?.maxRetries || 0;
  const requestId = originalOptions?.requestId || generateId();
  const retryDelay = originalOptions?.retryDelay || DEFAULT_RETRY_DELAY;

  const query = originalOptions?.query || null;
  const requestBody = originalOptions?.body || null;

  const requestOptions: RequestInit & Record<string, any> = {
    method: originalOptions?.method || "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      session,
      ...(originalOptions.headers as Record<string, string>),
    },
    requestId,
    retryDelay,
    maxRetries,
  };

  if (requestBody) {
    requestOptions.body =
      typeof requestBody !== "string" &&
      requestOptions.headers["Content-Type"] === "application/json"
        ? JSON.stringify(requestBody)
        : requestBody;
  }

  path = /^https?/.test(path) ? path : urlJoin(config.api.base, path);
  path = query ? `${path}?${serializeQueryParams(query)}` : path;

  try {
    const response = await fetch(path, requestOptions);
    const responseBody = await parseResponse(response);

    const parsedResponse = {
      ...response,
      body: responseBody,
      headers: response.headers,
    };

    if (!response.ok) {
      const error = handleCustomError(parsedResponse, localeErrors);
      const responseStatus = response.status;
      const shouldRetry =
        (maxRetries > 0 && attemptCount < maxRetries) ||
        (responseStatus >= 500 && attemptCount < 1);

      if (shouldRetry) {
        debugApiError("Retrying request", error, {
          path,
          requestId,
          maxRetries,
          body: requestBody,
          method: requestOptions.method,
          status: responseStatus,
          attempt: attemptCount + 1,
        });

        await sleep(retryDelay);
        return requestFn(path, originalOptions, attemptCount + 1);
      }

      debugApiError("Request failed", error, {
        path,
        requestId,
        body: requestBody,
        method: requestOptions.method,
        status: responseStatus,
        headers: requestOptions.headers,
      });

      throw parsedResponse;
    }

    return parsedResponse;
  } catch (e) {
    const error = handleCustomError(e ?? {}, localeErrors);
    const shouldRetry = attemptCount < maxRetries;

    if (shouldRetry) {
      debugApiError("Retrying on network error", error, {
        path,
        requestId,
        maxRetries,
        body: requestBody,
        method: requestOptions.method,
        attempt: attemptCount + 1,
      });

      await sleep(retryDelay);
      return requestFn(path, originalOptions, attemptCount + 1);
    }

    debugApiError("Request exception", error, {
      path,
      requestId,
      body: requestBody,
      method: requestOptions.method,
      headers: requestOptions.headers,
    });

    throw error;
  }
}

// @ts-ignore
window.apiRequest = request;
export default request;
