import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocalStorage } from "react-use";

import { CSS } from "sf/consts/style";
import { MINUTE } from "sf/consts/dateTime";
import { Para } from "sf/components/Styled";
import { Button } from "sf/components/Button";
import { useKeyDownEvent } from "sf/hooks/useKeyDownEvent";

import request from "src/lib/request";
import Modal from "src/components/Modal";
import { KEYS } from "sf/consts/keysAndMouse";

export default function ReleaseReload() {
  const [isNewRelease, setIsNewRelease] = useState(false);
  const intervalRef = useRef(null);

  const handleClick = useCallback(() => {
    window.location.reload();
  }, []);

  const [latestVersion, setLatestVersion] = useLocalStorage(
    "togal-release-version",
    null
  );

  const checkVersionFreshness = useCallback(
    async (shouldRefresh) => {
      try {
        const response = await request.head(`${window.location.origin}/index.html`);
        const newETag = response.headers.get("ETag");

        if (!latestVersion) {
          setLatestVersion(newETag);
          return;
        }

        if (newETag !== latestVersion) {
          setIsNewRelease(shouldRefresh);
          setLatestVersion(newETag);
        }
      } catch (error) {
        console.error(`Error during version check`, error);
      }
    },
    [latestVersion]
  );

  useEffect(() => {
    if (!checkVersionFreshness) return;

    checkVersionFreshness(false);

    intervalRef.current = window.setInterval(
      () => checkVersionFreshness(true),
      10 * MINUTE
    );

    return () => clearInterval(intervalRef.current);
  }, [checkVersionFreshness]);

  //Form submission event handler on Enter click
  useKeyDownEvent([KEYS.ENTER], handleClick, null, !isNewRelease);

  return import.meta.env.VITE_TGL_ENV !== "local" && isNewRelease ? (
    <Modal
      title="New version available"
      zIndex={CSS.Z_INDEX_MAX}
      maxWidth="300"
      buttons={
        <StyledButton
          fontColor={CSS.WHITE}
          value="Refresh"
          onClick={handleClick}
        />
      }
    >
      <StyledPara>
        There's a new version of TOGAL available. Please refresh your browser to
        use the latest features.
      </StyledPara>
    </Modal>
  ) : null;
}

const StyledButton = styled(Button)`
  width: 100%;
  background-color: ${CSS.MAINCOLOR};
`;

const StyledPara = styled(Para)`
  line-height: 1.5;
`;
