import { Feature, Point } from "geojson";
import { polygon } from "@turf/helpers";
import turfDifference from "@turf/difference";

import { getBufferedGeometry } from "../modes/mutate/utils";
import {
  HOLE_TYPE,
  GEOJSON_TYPES,
  DEFAULT_REGION_ID_ML,
} from "../consts/editor";
import { isFeaturePipelineCount } from "./pipeline";

const COLOR = [255, 255, 255, 255];
const COUNT_FEATURES_COLOR = [255, 40, 40, 255];
let CACHED_STATIC_FEATURES: Feature[] = [];

const DEFAULT_SIZE = 20;

export function getCirclePolygonFeature(
  feature: Feature<Point>,
  radius: number = DEFAULT_SIZE,
  steps: number = 20
) {
  const center = feature.geometry.coordinates;

  const coordinates = [];

  for (let i = 0; i <= steps; i++) {
    const angle = (i * 2 * Math.PI + Math.PI) / steps;
    const x = center[0] + radius * Math.cos(angle);
    const y = center[1] + radius * Math.sin(angle);
    coordinates.push([x, y]);
  }

  const polygonFeature = polygon([coordinates]);
  polygonFeature.properties = {
    ...feature.properties,
    guideType: "tentative",
    color: COUNT_FEATURES_COLOR,
    fillColor: COUNT_FEATURES_COLOR,
    lineColor: COUNT_FEATURES_COLOR,
  };

  return polygonFeature;
}

export function getPlumbingStaticFeatures(
  features: any,
  referenceInchesPerPixels: number
): Feature[] {
  if (CACHED_STATIC_FEATURES.length > 0) {
    return CACHED_STATIC_FEATURES;
  }

  const netFeatures = features
    .filter(
      (f: Feature) =>
        f?.properties?.className !== HOLE_TYPE &&
        f?.properties?.types?.includes(DEFAULT_REGION_ID_ML)
    )
    .map((f: Feature) => ({
      ...f,
      properties: {
        ...f.properties,

        guideType: "tentative",

        lineWidth: 0,
        color: COLOR,
        fillColor: COLOR,
        lineColor: COLOR,
      },
    }))
    .map((f: Feature) => {
      try {
        const buffered = getBufferedGeometry(f, -0.1);
        return buffered;
      } catch (error) {
        return f;
      }
    });

  const radius = DEFAULT_SIZE / referenceInchesPerPixels;

  const countFeatures = features
    .filter(isFeaturePipelineCount)
    .map((f: Feature<Point>) => {
      try {
        const ellipseShape = getCirclePolygonFeature(f, radius, 4);

        return ellipseShape;
      } catch (error) {
        return null;
      }
    })
    .filter(Boolean);

  const staticFeatures = netFeatures.map((feature: any) => {
    let featureWithCountDifference = feature;

    countFeatures.forEach((countFeature: any) => {
      const diff = turfDifference(featureWithCountDifference, countFeature);
      if (diff?.geometry?.type === GEOJSON_TYPES.Polygon) {
        featureWithCountDifference = diff;
      }
    });

    return featureWithCountDifference;
  });

  CACHED_STATIC_FEATURES = staticFeatures;

  return staticFeatures;
}
