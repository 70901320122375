import { CSS } from "sf/consts/style";

import { ReactComponent as StarredIcon } from "src/svg/projects/starred.svg";
import { ReactComponent as MyProjectsIcon } from "src/svg/projects/my_projects.svg";
import { ReactComponent as AllProjectsIcon } from "src/svg/projects/all_projects.svg";
import { ReactComponent as ArchivedProjectsIcon } from "src/svg/projects/archived.svg";
import { ReactComponent as SharedWithMeIcon } from "src/svg/projects/shared_with_me.svg";

import { PROJECT_LOCALES } from "sf/i18n";
import { getUserLocale } from "../Auth/utils";

export const MAX_ID_LENGTH = 36;
export const MAX_NAME_LENGTH = 128;
export const SET_MAX_NAME_LENGTH = 256;
export const PROJECT_MAX_NAME_LENGTH = 256;

export const SORT_TYPE = "sort";
export const SEPRATOR_TYPE = "separator";
export const SORT_ORDER_TYPE = "sortOrder";

export const FILTER_IDS = {
  all: "all",
  mine: "mine",
  shared: "shared",
  starred: "starred",
  archived: "archived",
};

export const PROJECTS_FILTERS = [
  {
    id: FILTER_IDS.all,
    icon: AllProjectsIcon,
    title: getUserLocale()[PROJECT_LOCALES].FILTER_ALL_PROJECTS,
    getData: (data: any) => data.filter((item: any) => item?.active),
    filter: (projectPermissions: any) => projectPermissions.FILTER_ALL,
  },
  {
    id: FILTER_IDS.starred,
    icon: StarredIcon,
    title: getUserLocale()[PROJECT_LOCALES].FILTER_PROJECTS_STARRED,
    filter: (projectPermissions: any) => projectPermissions.FILTER_STARRED,
    getData: (data: any, user: any) =>
      data.filter(
        (item: any) =>
          item?.active && (user?.metadata?.starredOrgs || []).includes(item?.id)
      ),
  },
  {
    id: FILTER_IDS.mine,
    icon: MyProjectsIcon,
    title: getUserLocale()[PROJECT_LOCALES].FILTER_PROJECTS_MINE,
    filter: (projectPermissions: any) => projectPermissions.FILTER_MINE,
    getData: (data: any, user: any) =>
      data.filter((item: any) => item?.active && item?.user_id === user?.id),
  },
  {
    id: FILTER_IDS.shared,
    icon: SharedWithMeIcon,
    title: getUserLocale()[PROJECT_LOCALES].FILTER_PROJECTS_SHARED,
    filter: (projectPermissions: any) => projectPermissions.FILTER_SHARED,
    getData: (data: any, sharee: any) =>
      data.filter(
        (item: any) =>
          item?.active &&
          item?.user_id !== sharee.id &&
          item?.users?.find((user: any) => user?.id === sharee?.id)
      ),
  },
  {
    id: FILTER_IDS.archived,
    icon: ArchivedProjectsIcon,
    title: getUserLocale()[PROJECT_LOCALES].FILTER_PROJECTS_ARCHIVED,
    filter: (projectPermissions: any) => projectPermissions.FILTER_ARCHIVED,
    getData: (data: any) => data.filter((item: any) => !item?.active),
  },
];

export const CONTEXT_MENU_IDS = {
  sep: "sep",
  star: "star",
  edit: "edit",
  delete: "delete",
  unstar: "unstar",
  restore: "restore",
  archive: "archive",
  duplicate: "duplicate",
  batchstar: "batchstar",
  batchunstar: "batchunstar",
  batchdelete: "batchdelete",
  batcharchive: "batcharchive",
  batchrestore: "batchrestore",
};

export const PROJECT_CONTEXT_MENU = [
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_STAR,
    fontColor: CSS.BLACK,
    id: CONTEXT_MENU_IDS.star,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.STAR(item) && filterID !== FILTER_IDS.archived,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_UNSTAR,
    fontColor: CSS.BLACK,
    id: CONTEXT_MENU_IDS.unstar,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.UNSTAR(item) && filterID !== FILTER_IDS.archived,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_DUPLICATE,
    fontColor: CSS.BLACK,
    id: CONTEXT_MENU_IDS.duplicate,
    filter: (_item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.DUPLICATE && filterID !== FILTER_IDS.archived,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_EDIT,
    fontColor: CSS.BLACK,
    id: CONTEXT_MENU_IDS.edit,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.EDIT(item) && filterID !== FILTER_IDS.archived,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_RESTORE,
    fontColor: CSS.BLACK,
    id: CONTEXT_MENU_IDS.restore,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.RESTORE(item) && filterID === FILTER_IDS.archived,
  },
  {
    title: "",
    type: SEPRATOR_TYPE,
    id: CONTEXT_MENU_IDS.sep,
    filter: (item: any, _filterID: string, projectPermissions: any) =>
      projectPermissions.ARCHIVE(item) || projectPermissions.DELETE(item),
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_ARCHIVE,
    fontColor: CSS.ERROR,
    id: CONTEXT_MENU_IDS.archive,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.ARCHIVE(item) && filterID !== FILTER_IDS.archived,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].CM_DELETE,
    fontColor: CSS.ERROR,
    id: CONTEXT_MENU_IDS.delete,
    filter: (item: any, filterID: string, projectPermissions: any) =>
      projectPermissions.DELETE(item) && filterID === FILTER_IDS.archived,
  },
];

export const SORT_CONTEXT_MENU_IDS = {
  sep: "sep",
  asc: "asc",
  desc: "desc",
  owner: "owner",
  alpha: "alpha",
  created: "created",
  modified: "modified",
};

export const PROJECT_SORT_CONTEXT_MENU = [
  {
    isDate: true,
    multiplier: 1,
    key: "updated",
    type: SORT_TYPE,
    title: getUserLocale()[PROJECT_LOCALES].SM_LAST_MODIFIED,
    id: SORT_CONTEXT_MENU_IDS.modified,
  },
  {
    isDate: true,
    multiplier: 1,
    key: "created",
    type: SORT_TYPE,
    title: getUserLocale()[PROJECT_LOCALES].SM_CREATED,
    id: SORT_CONTEXT_MENU_IDS.created,
  },
  {
    multiplier: 1,
    key: "ownerName",
    type: SORT_TYPE,
    title: getUserLocale()[PROJECT_LOCALES].SM_OWNER,
    id: SORT_CONTEXT_MENU_IDS.owner,
  },
  {
    key: "name",
    multiplier: 1,
    type: SORT_TYPE,
    title: getUserLocale()[PROJECT_LOCALES].SM_ALPHA,
    id: SORT_CONTEXT_MENU_IDS.alpha,
  },
  {
    title: "",
    type: SEPRATOR_TYPE,
    id: SORT_CONTEXT_MENU_IDS.sep,
    filter: (_item: any, _user: any, _filterID: string) => true,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].SM_O_ASC,
    type: SORT_ORDER_TYPE,
    id: SORT_CONTEXT_MENU_IDS.asc,
  },
  {
    title: getUserLocale()[PROJECT_LOCALES].SM_O_DESC,
    type: SORT_ORDER_TYPE,
    id: SORT_CONTEXT_MENU_IDS.desc,
  },
];

export const MULTI_SELECTION_ACTIONS = [
  {
    id: "batchstar",
    title: getUserLocale()[PROJECT_LOCALES].CM_STAR,
    filter: [FILTER_IDS.all, FILTER_IDS.mine, FILTER_IDS.shared],
    color: CSS.GRAY6,
  },
  {
    id: "batchunstar",
    title: getUserLocale()[PROJECT_LOCALES].CM_UNSTAR,
    filter: [FILTER_IDS.starred],
    color: CSS.GRAY6,
  },
  {
    id: "batcharchive",
    title: getUserLocale()[PROJECT_LOCALES].CM_ARCHIVE,
    filter: [
      FILTER_IDS.all,
      FILTER_IDS.mine,
      FILTER_IDS.shared,
      FILTER_IDS.starred,
    ],
    color: CSS.ERROR,
  },
  {
    id: "batchrestore",
    title: getUserLocale()[PROJECT_LOCALES].CM_RESTORE,
    filter: [FILTER_IDS.archived],
    color: CSS.GRAY6,
  },
  {
    id: "batchdelete",
    title: getUserLocale()[PROJECT_LOCALES].CM_DELETE,
    filter: [FILTER_IDS.archived],
    color: CSS.ERROR,
  },
];

export const GRID_SELECTION_TEMPLATE = "36px 1fr 100px";
export const GRID_LIST_TEMPLATE = "36px 36px 1fr 120px 120px 150px 120px 36px";
export const GRID_LIST_MOBILE_TEMPLATE = "2fr 1fr";

export const TOAST_MESSAGES = {
  STAR_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_STAR,
  },
  DUPLICATE_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_DUPLICATE,
  },
  ARCHIVE_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_ARCHIVE,
  },
  PERMISSION_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_PERMISSION,
  },
  DELETE_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_DELETE,
  },
  CREATE_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_CREATE,
  },
  EDIT_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_EDIT,
  },
  CREATE_CONFLICT_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_CONFLICT,
  },
  CREATE_FOLDER_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_CREATE_FOLDER,
  },
  EDIT_FOLDER_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_EDIT_FOLDER,
  },
  DELETE_FOLDER_ERROR: {
    children: getUserLocale()[PROJECT_LOCALES].TM_DELETE_FOLDER,
  },
};

export const BACKGROUND_COLORS = [
  "linear-gradient(285.92deg, rgba(225, 222, 222, 0.25) 0%, rgba(236, 236, 236, 0.1) 100%)",
  "linear-gradient(285.92deg, rgba(59, 182, 89, 0.25) 0%, rgba(105, 190, 38, 0.1) 56.25%, rgba(105, 190, 38, 0.1) 100%)",
  "linear-gradient(285.92deg, #FFFBCE 0%, #FEFADF 56.25%, #FFFCF3 100%)",
  "linear-gradient(285.92deg, rgba(255, 23, 248, 0.25) 0%, rgba(254, 5, 65, 0.1) 100%)",
  "linear-gradient(285.92deg, #D2F2ED 0%, #E4F8F5 56.25%, #F4FDFE 100%)",
  "linear-gradient(285.92deg, #FFD7BF 0%, #FDDFD3 56.25%, #FEF4F3 100%)",
  "linear-gradient(285.92deg, #E9E7FF 0%, #E3F2F1 56.25%, #F9FBF3 100%)",
  "linear-gradient(285.92deg, #D3CCFF 0%, #E0E5FF 56.25%, #F3FDFE 100%)",
];

export const PROJECT_EVENTS = {
  STAR: "USER_STAR_PROJECT",
  CREATE: "USER_CREATE_PROJECT",
  DELETE: "USER_DELETE_PROJECT",
  ARCHIVE: "USER_ARCHIVE_PROJECT",
  DUPLICATE: "USER_DUPLICATE_PROJECT",
  NAVIGATION: "NAVIGATION_PROJECTS_PAGE",
};

export const DEFAULT_ALERT = {
  title: "",
  visible: false,
  info: "",
  description: "",
  cancel: () => {},
  confirm: () => {},
};
